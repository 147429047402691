import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Img from "../assets/login.png";
import { useLocation, useNavigate } from "react-router-dom";
import { HooksForm, PrimaryBtn } from "../components/inputs";
import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";
import { apiCall } from "../data/useGenFetcher";
import useErrorStore from "../data/Stores/ErrrorStore";
import useAuthStore from "../data/Stores/AuthStore";

const Login = () => {
	return (
		<div>
			<Header />
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	let navigate = useNavigate(),
		{ returnErrors } = useErrorStore(),
		{ login } = useAuthStore(),
		{ state } = useLocation();

	const methods = useForm({
			defaultValues: {
				email: "",
				password: "",
			},
		}),
		[loading, setLoading] = useState(null),
		handleSubmit = async data => {
			if (!data?.email || !data?.password) return;
			setLoading(true);
			let { response, errArr, errMsg } = await apiCall({
				url: "/api/v1/user/login",
				type: "put",
				data,
			});
			// console.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				login(response);
				// loadUser();
				navigate(state?.next || "/", { state: state?.plan || state });
				return;
			}
			setLoading(false);
		};

	return (
		<div
			style={{
				boxShadow: "0px 10px 20px 0px #0000001A",
				background:
					"linear-gradient(270deg, rgba(16, 81, 131, 0.047) 50%, rgba(37, 36, 114, 0.1) 85.42%)",
			}}
			className="tw-h-screen tw-flex tw-relative tw-justify-end tw-items-center tw-py-10">
			<div className="">
				<img
					src={Img}
					alt=""
					className="tw-h-[400px] tw-mr-1 tw-hidden lg:tw-block"
				/>
				<div className="tw-absolute tw-inset-0 tw-h-full container tw-flex tw-justify-center tw-items-center">
					<div className="tw-bg-white lg:tw-h-96 tw-flex tw-flex-col lg:tw-flex-row lg:tw-w-5/6 tw-w-full tw-mx-auto lg:tw-items-center tw-justify-between tw-p-10">
						<div>
							<h2 className="tw-text-2xl tw-font-medium tw-text-black satoshi">
								WELCOME,
								<br /> Nice to see you again!
							</h2>
							<h3 className="tw-mt-10 tw-text-[15px] tw-font-normal satoshi tw-text-black">
								New to EDU SMS?
							</h3>
							<h3
								onClick={() => navigate("/register")}
								className="tw-mt- tw-text-[15px] tw-font-bold tw-cursor-pointer satoshi tw-text-blue">
								Create an account
							</h3>
						</div>
						<div className="tw-mt-10 lg:tw-mt-0">
							<FormProvider {...methods}>
								<form onSubmit={methods.handleSubmit(handleSubmit)}>
									<div className="tw-space-y-4">
										{/* Email */}
										<HooksForm
											rules={{
												required: "This field is required",
												pattern: {
													value:
														/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/g,
													message: "You must enter a valid email address",
												},
											}}
											methods={methods}
											name="email"
											placeholder={"Email Address"}
											label={"EMAIL"}
											css={"tw-max-w-md"}
											type="email"
										/>
										{/* Password */}
										<HooksForm
											rules={{
												required: "This field is required",
												pattern: {
													value:
														/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
													message:
														"Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
												},
											}}
											methods={methods}
											name="password"
											type="password"
											label={"PASSWORD"}
											placeholder={"Enter password"}
										/>

										<div className="tw-flex tw-justify-between tw-items-center tw-pt-5">
											<div className="tw-flex tw-items-center tw-gap-2">
												<input
													type="checkbox"
													name=""
													className="tw-size-4 tw-rounded-sm"
													id=""
												/>
												<small className="tw-text-sm tw-font-normal tw-text-scgreen">
													Remember Me
												</small>
											</div>
											<small
												onClick={() => navigate("/forgotpassword")}
												className="tw-text-xs tw-cursor-pointer tw-font-normal tw-flex tw-justify-end tw-text-[#979ea6] satoshi">
												Forgot Password?
											</small>
										</div>
									</div>
									<div className="tw-mt-6">
										<PrimaryBtn
											// onClick={handleSubmit}
											loading={loading}
											text={"Sign In"}
											width={
												"tw-bg-yellow tw-h-10 tw-w-32 tw-rounded-xl tw-text-base tw-font-bold tw-text-black satoshi"
											}
										/>
									</div>
								</form>
							</FormProvider>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
