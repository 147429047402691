import React, { useEffect } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Img from "../../assets/icons/checkoutdone.svg";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../../data/Stores/AuthStore";

const CheckOutDone = () => {
	return (
		<div>
			<Header />
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	let { state } = useLocation(),
		navigate = useNavigate(),
		{ isAuth } = useAuthStore();

	useEffect(() => {
		if (!state || !isAuth) navigate(-1);
	}, [state, navigate, isAuth]);

	console.log({ state });

	return (
		<section className="tw-mt-8 tw-w-full tw-py-20">
			<div className="container">
				<div className="lg:tw-w-1/2 tw-mx-auto">
					<div
						style={{
							boxShadow: "0px 20px 40px 0px #080A1E1A",
						}}
						className="tw-mt-8 tw-bg-[#EAEEFF] tw-w-full tw-px-4 tw-py-5">
						<img src={Img} alt="" className="tw-mx-auto" />
						<h2 className="lg:tw-text-4xl tw-text-2xl tw-mt-5 tw-font-bold kum tw-text-[#0F236B] tw-text-center">
							CONGRATULATIONS!
						</h2>
						<p className="tw-max-w-lg tw-text-center tw-mx-auto tw-text-base tw-font-normal kum tw-text-black">
							You have successfully completed your registartion and onboarding
							process on CESMAS.
						</p>
						<h5 className="tw-text-base tw-font-normal kum tw-text-black tw-mt-5 tw-text-center">
							Your school ID:{" "}
							<span className="tw-font-bold tw-text-yellow">
								{state?.schoolId}
							</span>
						</h5>
						<p className="tw-max-w-lg tw-text-center tw-mt-4 tw-mx-auto tw-text-base tw-font-normal kum tw-text-black">
							You can reset your password and username once you login, use the
							links below to access your platforms
						</p>
						<h6 className="tw-mt-4 tw-text-base tw-font-normal tw-text-black kum tw-text-center">
							Your Admin URL:{" "}
							<span className="tw-text-blue tw-cursor-pointer">
								{state?.adminURL}
							</span>
						</h6>
					</div>
				</div>
			</div>
		</section>
	);
};

export default CheckOutDone;
