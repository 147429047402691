import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Img from "../../assets/register.png";
import { PrimaryBtn, TextInputs } from "../../components/inputs";
import { BigText } from "../event";
import { useLocation, useNavigate } from "react-router-dom";
import { apiCall, getUrl } from "../../data/useGenFetcher";
import useErrorStore from "../../data/Stores/ErrrorStore";
import { toast } from "react-toastify";
import moment from "moment";
import useAuthStore from "../../data/Stores/AuthStore";

const Register = () => {
	return (
		<div>
			<Header />
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	let navigate = useNavigate(),
		{ returnErrors } = useErrorStore(),
		{ institutionType } = useAuthStore(),
		[state, setState] = useState({}),
		items = useLocation()?.state,
		// textChange = e => {
		// 	let { name, value } = e?.target;
		// 	setState({ ...state, [name]: value });
		// },
		textChange = event => {
			const newFormInput = { ...state };
			let { name, value, type, checked, title, id } = event.target;
			if (["checkbox", "radio"]?.includes(type)) {
				if (["checkbox"]?.includes(type)) {
					let thisArr = newFormInput[name] || [];
					if (checked) {
						thisArr = [...new Set([...thisArr, id || title])];
					} else {
						thisArr = thisArr?.filter(ic => ic !== (id || title));
					}

					newFormInput[name] = thisArr;
				} else newFormInput[name] = id || title;
			}
			if (!["checkbox", "radio"]?.includes(type)) newFormInput[name] = value;
			setState(newFormInput);
		},
		[loading, setLoading] = useState(null),
		handleSubmit = async e => {
			e?.preventDefault();
			let errArr2 = [];

			if (!state?.schoolName)
				errArr2?.push({
					message: "School Name is reqiured",
					path: "schoolName",
				});
			if (!state?.schoolSubdomain)
				errArr2?.push({
					message: "School Preffered Domain is reqiured",
					path: "schoolSubdomain",
				});
			if (!state?.noOfStudents)
				errArr2?.push({
					message: "Number of students for the School is reqiuired",
					path: "noOfStudents",
				});
			if (errArr2?.length > 0) return returnErrors(errArr2);

			setLoading(true);
			let { response, errArr, errMsg } = await apiCall({
				url: "/api/v1/user?prevalidate=prevalidate",
				type: "post",
				data: {
					...state,
					nextScreen: getUrl({ addition: state?.schoolSubdomain }),
				},
			});
			// console.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				navigate("/register/details-register", { state });
				return;
			}
			setLoading(false);
		};

	useEffect(() => {
		if (items) setState({ ...state, ...items });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	return (
		<section
			style={{
				boxShadow: "0px 10px 20px 0px #0000001A",
				background:
					"linear-gradient(270deg, rgba(16, 81, 131, 0.047) 50%, rgba(37, 36, 114, 0.1) 85.42%)",
			}}
			className="tw-w-full tw-min-h-screen lg:tw-py-14">
			<div
				style={{
					boxShadow: "0px 10px 20px 0px #0000001A",
				}}
				className="container tw-bg-white tw-py-10 lg:tw-items-center tw-grid tw-h-full lg:tw-grid-cols-2">
				<div className="lg:tw-pl-12">
					<BigText css={"tw-uppercase"} text={"registration"} />
					<form action="">
						<div className="tw-space-y-4">
							<TextInputs
								css={"tw-w-full"}
								label={"School Name"}
								placeholder={"Full Name"}
								value={state?.schoolName}
								onChange={textChange}
								name="schoolName"
							/>
							<div className="tw-w-full lg:tw-w-[440px]">
								<div className="tw-mb-3">
									{" "}
									<strong className="tw-text-base satoshi tw-font-medium tw-text-[#3e4347]">
										Institution Type
									</strong>
								</div>
								<ul className="tw-items-center tw-w-full tw-text-sm tw-font-medium tw-text-gray-900 tw-bg-white tw-border tw-border-gray-200 tw-rounded-lg sm:tw-flex">
									{institutionType?.docs?.map((item, i) => (
										<li
											key={i}
											className="tw-w-full tw-border-b tw-border-gray-200 sm:tw-border-b-0 sm:tw-border-r">
											<div className="tw-flex tw-items-center tw-ps-3">
												<input
													type="checkbox"
													className="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded focus:tw-ring-blue-500 focus:tw-ring-2"
													name="institutionType"
													onChange={textChange}
													checked={state?.["institutionType"]?.includes(
														item?._id
													)}
													id={item?._id}
													title={item?.name}
												/>
												<label
													htmlFor={item?._id}
													className="tw-w-full tw-py-3 tw-ms-2 tw-text-sm tw-font-medium tw-text-gray-900 tw-capitalize">
													{item?.name}
												</label>
											</div>
										</li>
									))}
								</ul>
							</div>
							<TextInputs
								css={"tw-w-full"}
								label={"Number Of Student(s)"}
								placeholder={"Enter Number Of Student(s)"}
								value={state?.noOfStudents}
								onChange={textChange}
								name="noOfStudents"
								type="number"
							/>
							<TextInputs
								css={"tw-w-full"}
								label={"School Email"}
								placeholder={"School Email"}
								value={state?.schoolEmail}
								onChange={textChange}
								name="schoolEmail"
								type="email"
							/>
							<TextInputs
								css={"tw-w-full"}
								label={"School Address"}
								type={"textarea"}
								placeholder={"School Address"}
								value={state?.schoolAddress}
								onChange={textChange}
								name="schoolAddress"
							/>
							<TextInputs
								css={"tw-w-full"}
								label={"Date Founded"}
								placeholder={"Date Founded"}
								value={state?.yearFounded}
								onChange={textChange}
								name="yearFounded"
								type="date"
								max={moment().format("YYYY-MM-DD")}
							/>
							<TextInputs
								css={"tw-w-full"}
								label={"Preferred Domain"}
								type={"text"}
								placeholder={"Enter preferred domain"}
								value={state?.schoolSubdomain}
								onChange={textChange}
								name="schoolSubdomain"
							/>
						</div>
						<div className="tw-mt-8">
							<h5 className="tw-text-xs tw-font-normal tw-text-[#979ea6] satoshi">
								Have an account?{" "}
								<span
									onClick={() => navigate("/login")}
									className="tw-font-bold tw-cursor-pointer satoshi tw-text-xs tw-text-blue">
									Sign in
								</span>
							</h5>
							<PrimaryBtn
								onClick={handleSubmit}
								loading={loading}
								text={"Continue"}
								width={
									"tw-bg-yellow tw-mt-4 tw-h-10 tw-w-40 tw-rounded-md tw-text-base tw-font-bold tw-text-black satoshi"
								}
							/>
						</div>
					</form>
				</div>
				<div className="lg:tw-pr-12">
					<img src={Img} alt="" className="tw-h-full" />
				</div>
			</div>
		</section>
	);
};

export default Register;
