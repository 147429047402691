import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Img from "../../assets/register.png";
import { HooksForm, PrimaryBtn } from "../../components/inputs";
import { BigText } from "../event";
import { useLocation, useNavigate } from "react-router-dom";
import useErrorStore from "../../data/Stores/ErrrorStore";
import { useForm, FormProvider } from "react-hook-form";
import { apiCall, getUrl } from "../../data/useGenFetcher";
import { toast } from "react-toastify";
import useAuthStore from "../../data/Stores/AuthStore";

const Register = () => {
	return (
		<div>
			<Header />
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	let navigate = useNavigate(),
		{ returnErrors } = useErrorStore(),
		{ login } = useAuthStore(),
		{ state } = useLocation();

	useEffect(() => {
		if (!state) navigate(-1);
	}, [state, navigate]);

	const methods = useForm({
			defaultValues: {
				email: "",
				password: "",
			},
		}),
		[loading, setLoading] = useState(null),
		handleSubmit = async data => {
			console.log({ data });
			let errArr2 = [];

			if (!data?.email)
				errArr2?.push({
					message: "Email is reqiured",
					path: "email",
				});
			if (!data?.firstName)
				errArr2?.push({
					message: "First Name is reqiuired",
					path: "firstName",
				});
			if (!data?.lastName)
				errArr2?.push({
					message: "Last Name is reqiured",
					path: "lastName",
				});
			if (!data?.password)
				errArr2?.push({
					message: "Password is reqiured",
					path: "password",
				});
			if (data?.password)
				if (data?.password !== data?.confirmPassword)
					errArr2?.push({
						message: "Password do not match",
						path: "email",
					});
			if (errArr2?.length > 0) return returnErrors(errArr2);
			if (!data?.email || !data?.password) return;
			setLoading(true);
			let { response, errArr, errMsg } = await apiCall({
				url: "/api/v1/user",
				type: "post",
				data: {
					...state,
					...data,
					nextScreen: `${getUrl({
						addition: state?.schoolSubdomain,
					})}/activate`,
				},
			});
			// console.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				login(response);
				navigate("/pricing");
				return;
			}
			setLoading(false);
		};

	return (
		<section
			style={{
				boxShadow: "0px 10px 20px 0px #0000001A",
				background:
					"linear-gradient(270deg, rgba(16, 81, 131, 0.047) 50%, rgba(37, 36, 114, 0.1) 85.42%)",
			}}
			className="tw-w-full tw-min-h-screen lg:tw-py-14">
			<div
				style={{
					boxShadow: "0px 10px 20px 0px #0000001A",
				}}
				className="container tw-bg-white tw-py-10 lg:tw-items-center tw-grid tw-h-full lg:tw-grid-cols-2">
				<div className="lg:tw-pl-12">
					<BigText css={"tw-uppercase"} text={"registration"} />
					{/* <form action="">
						<div className="tw-space-y-4">
							<TextInputs
								css={"tw-w-full"}
								label={"Full Name"}
								placeholder={"Full Name"}
							/>
							<TextInputs
								css={"tw-w-full"}
								label={"Email"}
								type={"email"}
								placeholder={"Enter email"}
							/>
							<TextInputs
								css={"tw-w-full"}
								label={"Phone number"}
								type={"tel"}
								placeholder={"Phone number"}
							/>
							<PasswordInputs
								label={"Password"}
								placeholder={"Enter Password"}
							/>
							<PasswordInputs
								label={"Confirm Password"}
								placeholder={"Confirm Password"}
							/>
						</div>
						<div className="tw-mt-8">
							<h5 className="tw-text-xs tw-font-normal tw-text-[#979ea6] satoshi">
								Have an account?{" "}
								<span
									onClick={() => navigate("/login")}
									className="tw-font-bold tw-cursor-pointer satoshi tw-text-xs tw-text-blue">
									Sign in
								</span>
							</h5>
							<div className="tw-flex tw-gap-5 tw-items-center">
								<div>
									<h6
										onClick={() => navigate(-1)}
										className="tw-font-bold tw-mt-5 tw-text-[15px] tw-text-yellow tw-cursor-pointer satoshi">
										Previous
									</h6>
								</div>
								<div>
									<button
										onClick={handleSubmit}
										className="tw-bg-yellow tw-mt-4 tw-h-10 tw-w-32 tw-rounded-md tw-text-base tw-font-bold tw-text-black satoshi">
										Next
									</button>
								</div>
							</div>
						</div>
					</form> */}
					<FormProvider {...methods}>
						<form onSubmit={methods.handleSubmit(handleSubmit)}>
							<div className="tw-space-y-4">
								{/* First Name */}
								<HooksForm
									rules={{
										required: "This field is required",
									}}
									methods={methods}
									name="firstName"
									placeholder={"Johnson"}
									label={"First Name"}
									css={"tw-w-full"}
									type="text"
								/>
								{/* Last Name */}
								<HooksForm
									rules={{
										required: "This field is required",
									}}
									methods={methods}
									name="lastName"
									placeholder={"James"}
									label={"Last Name"}
									css={"tw-w-full"}
									type="text"
								/>
								{/* Email */}
								<HooksForm
									rules={{
										required: "This field is required",
										pattern: {
											value:
												/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/g,
											message: "You must enter a valid email address",
										},
									}}
									methods={methods}
									name="email"
									placeholder={"Email Address"}
									label={"Email"}
									css={"tw-w-full"}
									type="email"
								/>
								{/* Phone Number */}
								<HooksForm
									rules={{
										required: "This field is required",
									}}
									methods={methods}
									name="telephone"
									css={"tw-w-full"}
									label={"Phone number"}
									type={"tel"}
									placeholder={"Phone number"}
								/>
								{/* Password */}
								<HooksForm
									rules={{
										required: "This field is required",
										pattern: {
											value:
												/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
											message:
												"Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
										},
									}}
									methods={methods}
									name="password"
									type="password"
									label={"Password"}
									placeholder={"Enter password"}
								/>
								{/* Confirm Password */}
								<HooksForm
									rules={{
										required: "This field is required",
										pattern: {
											value:
												/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
											message:
												"Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
										},
									}}
									methods={methods}
									name="confirmPassword"
									type="password"
									label={"Confirm Password"}
									placeholder={"Enter password again"}
								/>

								<div className="tw-flex tw-justify-between tw-items-center tw-pt-5">
									<div className="tw-flex tw-items-center tw-gap-2"></div>
									<h5 className="tw-text-xs tw-font-normal tw-text-[#979ea6] satoshi">
										Have an account?{" "}
										<span
											onClick={() => navigate("/login")}
											className="tw-font-bold tw-cursor-pointer satoshi tw-text-xs tw-text-blue">
											Sign in
										</span>
									</h5>
								</div>
							</div>
							<div className="tw-flex tw-gap-5 tw-items-center">
								<div>
									<PrimaryBtn
										onClick={() => navigate("/register", { state })}
										text={"Previous"}
										width={
											"tw-font-bold tw-mt-5 tw-text-[15px] tw-text-yellow tw-cursor-pointer satoshi"
										}
										type="button"
									/>
								</div>
								<div>
									<PrimaryBtn
										// onClick={handleSubmit}
										loading={loading}
										text={"Next"}
										width={
											"tw-bg-yellow tw-mt-4 tw-h-10 tw-w-32 tw-rounded-md tw-text-base tw-font-bold tw-text-black satoshi"
										}
									/>
								</div>
							</div>
						</form>
					</FormProvider>
				</div>
				<div className="lg:tw-pr-12">
					<img src={Img} alt="" className="tw-h-full" />
				</div>
			</div>
		</section>
	);
};

export default Register;
