import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { FaSearch } from "react-icons/fa";
import { BigText } from "./event";
import Mail from "../assets/icons/mail.svg";
import Wallet from "../assets/icons/wallet.svg";
import Dollar from "../assets/icons/dollar.svg";
import Star from "../assets/icons/star.svg";
import Ariticle from "../assets/articles.png";
import { JoinSection, Trusted } from "./home";

const Faq = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <MainSection />
      <Recent />
      <MoreQuestions />
      <Trusted />
      <JoinSection />
      <Footer />
    </div>
  );
};

const HeroSection = () => {
  return (
    <section className="tw-w-full tw-h-screen faq">
      <div className="container tw-relative tw-h-full tw-flex tw-justify-center tw-items-center">
        <div className="">
          <div className="tw-w-40 tw-h-10 tw-mx-auto tw-bg-[#FFFFFF0D] tw-border-[0.2px] tw-border-white tw-text-xs tw-font-bold tw-text-white tw-rounded-full tw-flex tw-justify-center tw-items-center">
            Empowering Education
          </div>
          <h1 className="tw-text-white tw-font-bold lg:tw-text-5xl tw-text-2xl lg:tw-leading-[60px] kum tw-text-center lg:tw-max-w-3xl">
            Ask Us Anything; Your Questions, Answered
          </h1>
          <p className="lg:tw-text-base tw-text-xs tw-font-medium tw-max-w-xl tw-mx-auto tw-text-center satoshi tw-text-white">
            Empowering you with knowledge. Find answers to your queries in our
            comprehensive FAQ page
          </p>
        </div>
        <div className="tw-absolute tw-bottom-8">
          <div className="tw-flex tw-justify-center tw-h-10 tw-w-64 tw-rounded-xl tw-mx-auto tw-bg-white tw-bg-opacity-80">
            <input
              type="text"
              placeholder="Search here"
              className="tw-h-full tw-w-full tw-bg-transparent tw-pl-10 tw-text-sm tw-font-normal satosho tw-text-plain"
            />
            <div className="tw-absolute tw-top-3 tw-left-2">
              <FaSearch />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const MainSection = () => {
  const [ans, setAns] = useState(0);
  const Arr = [
    {
      icon: Mail,
      title: "How do I change my account email?",
      desc: "You can log in to your account and change it from your Profile > Edit Profile. Then go to the general tab to change your email.",
    },
    {
      icon: Wallet,
      title: "What should I do if my payment fails?",
      desc: "If your payment fails, you can use the (COD) payment option, if available on that order. If your payment is debited from your account after a payment failure, it will be credited back within 7-10 days.",
    },
    {
      icon: Mail,
      title: "How do I change my account email?",
      desc: "You can log in to your account and change it from your Profile > Edit Profile. Then go to the general tab to change your email.",
    },
    {
      icon: Mail,
      title: "How do I change my account email?",
      desc: "You can log in to your account and change it from your Profile > Edit Profile. Then go to the general tab to change your email.",
    },
    {
      icon: Mail,
      title: "How do I change my account email?",
      desc: "You can log in to your account and change it from your Profile > Edit Profile. Then go to the general tab to change your email.",
    },
    {
      icon: Dollar,
      title: "How do I change my account email?",
      desc: "You can log in to your account and change it from your Profile > Edit Profile. Then go to the general tab to change your email.",
    },
  ];
  return (
    <section className="tw-py-16 tw-bg-white tw-w-full">
      <div className="container">
        <BigText
          text={"Frequently Asked Questions"}
          align={"center"}
          color={"black"}
        />
        <div className="tw-mt-10 lg:tw-w-full tw-w-5/6 tw-mx-auto tw-grid lg:tw-grid-cols-3 tw-gap-10">
          {Arr?.map((it) => (
            <div className="">
              <img src={it?.icon} alt="" className="" />
              <h4 className="tw-text-sm tw-pt-3 tw-text-[#53686A] tw-font-medium satoshi">
                {it?.title}
              </h4>
              <p className="tw-text-[11px] tw-font-normal satoshi tw-text-[#53686A]">
                {it?.desc}
              </p>
            </div>
          ))}
        </div>
        <div className="tw-mt-20 tw-w-full tw-grid lg:tw-grid-cols-2 tw-gap-12">
          <div className="tw-space-y-4">
            <h3 className="tw-text-4xl tw-font-bold satoshi tw-text-plain">
              FAQs.
            </h3>
            {Arr?.map((it, i) => (
              <div
                onClick={() => setAns(i)}
                key={i}
                className={`tw-h-14 tw-cursor-pointer tw-w-full tw-px-6 tw-flex tw-justify-between tw-items-center ${
                  ans === i
                    ? "tw-bg-black tw-transition-transform tw-ease-in-out tw-duration-1000 tw-rounded-xl"
                    : "tw-border-dashed tw-bg-[#1B1B1B05] tw-rounded-xl tw-border tw-border-[#E7EEEC]"
                }`}
              >
                <div>
                  <strong
                    className={`tw-text-lg tw-font-medium satoshi ${
                      ans === i ? "tw-text-white" : "tw-text-black"
                    }`}
                  >
                    {it?.title}
                  </strong>
                </div>
                {ans === i && <img src={Star} alt="" className="" />}
              </div>
            ))}
          </div>
          <div className="">
            <h3 className="tw-text-4xl tw-font-bold satoshi tw-text-plain">
              Ans.
            </h3>
            <div className="tw-h-full tw-bg-[#2242B4] tw-w-full tw-rounded-3xl tw-px-8 tw-py-10">
              <img src={Star} alt="" className="" />
              <p className="tw-pt-7 tw-text-2xl lg:tw-w-3/4 tw-font-normal satoshi tw-text-white">
                {Arr[ans]?.desc}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Recent = () => {
  const Articles = [
    {
      category: "Finance and payment",
      title: "Managing All Finances and Payments In Your Schools",
    },
    {
      category: "Finance and payment",
      title: "Managing All Finances and Payments In Your Schools",
    },
    {
      category: "Finance and payment",
      title: "Managing All Finances and Payments In Your Schools",
    },
  ];
  return (
    <section className="tw-w-full tw-py-16 tw-bg-white">
      <div className="container">
        <BigText
          align={"center"}
          color={"black"}
          text={"RECENT ARTICLES"}
          css={"kum"}
        />
        <div className="tw-mt-10 tw-grid lg:tw-grid-cols-3 tw-gap-9">
          {Articles?.map((it, i) => (
            <div key={i} className="">
              <img src={Ariticle} alt="" className="" />
              <div className="tw-w-40 tw-h-6 tw-border tw-border-[#0000001A] tw-bg-slate-50 tw-mt-4 tw-flex tw-justify-center tw-items-center">
                <small className="tw-text-xs tw-font-medium satoshi tw-text-[#000000A6]">
                  {it?.category}
                </small>
              </div>
              <h5 className="tw-pt-2 tw-font-normal tw-text-xl satoshi tw-textblack">
                {it?.title}
              </h5>
              <strong className="tw-pt-3 tw-font-bold tw-text-base tw-text-[#2242B4]">
                Read
              </strong>
            </div>
          ))}
        </div>
        <div className="tw-mt-12 tw-flex tw-w-full tw-justify-center">
          <button className="tw-h-12 tw-w-36 tw-bg-blue tw-rounded-full tw-text-base tw-font-normal tw-text-white">
            Read the blog
          </button>
        </div>
      </div>
    </section>
  );
};

const MoreQuestions = () => {
  return (
    <section className="tw-py-14 tw-mb-20 tw-bg-[#F2FDFF] tw-w-full">
      <div className="container tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between lg:tw-items-center">
        <div>
          {" "}
          <h4 className="tw-text-2xl tw-font-bold satoshi tw-text-black">
            Still have questions?
          </h4>
          <p className="tw-text-base tw-font-normal tw-text-black satoshi">
            Can’t find the answer you’re looking for? Please chat to our
            friendly team.
          </p>
        </div>
        <button className="tw-bg-yellow tw-h-12 tw-w-40 tw-rounded tw-text-base tw-font-bold tw-text-white satoshi">
          Get in touch
        </button>
      </div>
    </section>
  );
};

export default Faq;
