import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Img from "../../assets/icons/checkout-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../../data/Stores/AuthStore";
import { apiCall, getUrl, numberWithCommas } from "../../data/useGenFetcher";
import { PrimaryBtn } from "../../components/inputs";
import useErrorStore from "../../data/Stores/ErrrorStore";
import { toast } from "react-toastify";

const CheckOut = () => {
	return (
		<div>
			<Header />
			<MainSection />
			<Footer />
		</div>
	);
};

const MainSection = () => {
	const navigate = useNavigate(),
		{ state } = useLocation(),
		{ school, isAuth, plans } = useAuthStore(),
		[plan, setPlan] = useState(null),
		[proceed, setProceed] = useState(null),
		[stage, setStage] = useState("initialize"),
		{ returnErrors } = useErrorStore();

	useEffect(() => {
		if (state) plans?.docs?.map(it => it?._id === state?._id && setPlan(it));
	}, [state, plans]);

	useEffect(() => {
		if (!state || !isAuth) navigate(-1);
	}, [state, navigate, isAuth]);

	let [loading, setLoading] = useState(null),
		handleSubmit = async e => {
			e?.preventDefault();

			let data = {
				plan: state?._id,
				nextScreen: getUrl({ addition: school?.schoolSubdomain }),
			};
			if (proceed?.reference) data.reference = proceed?.reference;

			setLoading(true);
			let { response, errArr, errMsg } = await apiCall({
				url: `/api/v1/subscription/${stage}`,
				type: "post",
				data,
			});
			// console.log({ response, errArr, errMsg });
			if (errArr) {
				setLoading(false);
				return returnErrors(errArr);
			}
			if (errMsg) {
				setLoading(false);
				return toast.error(errMsg);
			}
			setLoading(false);
			if (response) {
				setProceed(response?.data || response);
				if (stage === "initialize") setStage("subscribe");
				if (stage === "subscribe")
					navigate("/pricing/check-out-done", {
						state: response?.school || response?.data || response,
					});
			}
			setLoading(false);
		};

	return (
		<section className="tw-mt-8 tw-w-full tw-py-20">
			<div className="container">
				<div className="lg:tw-w-1/2 tw-mx-auto">
					<h5 className="tw-text-base tw-font-medium satoshi tw-text-[#3E4347]">
						{plan ? (
							<span className="tw-capitalize">{plan?.title} plan </span>
						) : null}
						No of students
					</h5>
					<input
						type="text"
						name=""
						placeholder="100"
						className="tw-h-12 tw-w-full tw-pl-6 tw-rounded-lg tw-border tw-border-[#CDCDCD] tw-text-sm satoshi tw-font-normal tw-text-black placeholder:tw-font-light"
						id=""
						value={school?.noOfStudents}
						readOnly
					/>
					<div
						style={{
							boxShadow: "0px 20px 40px 0px #080A1E1A",
						}}
						className="tw-mt-8 tw-bg-[#EAEEFF] tw-w-full tw-py-5">
						<img src={Img} alt="" className="tw-mx-auto" />
						<div className="tw-hidden">
							<form
								onSubmit={handleSubmit}
								action=""
								className="tw-mt-5 tw-w-80 tw-mx-auto">
								<div className="tw-space-y-4">
									<DetailsInput
										placeholder={"Full name"}
										label={"Name on card"}
									/>
									<DetailsInput
										placeholder={"0000 0000 0000 0000"}
										label={"Card Number"}
									/>
									<div className="tw-grid tw-grid-cols-2 tw-gap-4">
										<DetailsInput placeholder={"000"} label={"CVV"} />
										<DetailsInput placeholder={"08/34"} label={"Expiry date"} />
									</div>
								</div>
								<div className="tw-flex tw-justify-center tw-w-1/2 tw-mx-auto tw-mt-8">
									<button className="tw-bg-yellow tw-h-8 tw-w-full tw-rounded-lg tw-text-white tw-text-sm tw-font-medium kum">
										Continue to pay
									</button>
								</div>
							</form>
						</div>
						<div className="tw-px-2 lg:tw-px-6">
							{proceed && proceed?.toPay ? (
								<>
									<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center">
										<h5 className="tw-text-main tw-font-normal tw-text-base satoshi">
											Original Price:
										</h5>
										<div>
											<strong className="tw-text-main tw-font-bold tw-text-base satoshi">
												NGN{" "}
												{numberWithCommas(Number(proceed?.amount).toFixed(2))}
											</strong>
										</div>
									</div>
									<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center">
										<h5 className="tw-text-main tw-font-normal tw-text-base satoshi">
											No of Student(s)
										</h5>
										<div>
											<strong className="tw-text-main tw-font-bold tw-text-base satoshi">
												{numberWithCommas(school?.noOfStudents)}
											</strong>
										</div>
									</div>
									<div className="tw-mt-5 tw-justify-between tw-items-center tw-hidden">
										<h5 className="tw-text-main tw-font-normal tw-text-base satoshi">
											Charges
										</h5>
										<div>
											<strong className="tw-text-main tw-font-bold tw-text-base satoshi">
												NGN{" "}
												{numberWithCommas(Number(proceed?.charges).toFixed(2))}
											</strong>
										</div>
									</div>
									<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center tw-border-t tw-border-secondary">
										<h5 className="tw-text-main tw-font-bold tw-text-2xl satoshi">
											Total
										</h5>
										<div>
											<strong className="tw-text-main tw-font-bold tw-text-2xl satoshi">
												NGN{" "}
												{numberWithCommas(
													Number(
														proceed?.amount * school?.noOfStudents
													).toFixed(2)
												)}
											</strong>
										</div>
									</div>
								</>
							) : (
								<>
									<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center">
										<h5 className="tw-text-main tw-font-normal tw-text-base satoshi">
											Original Price:
										</h5>
										<div>
											<strong className="tw-text-main tw-font-bold tw-text-base satoshi">
												NGN {numberWithCommas(Number(state?.amount).toFixed(2))}
											</strong>
										</div>
									</div>
									<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center">
										<h5 className="tw-text-main tw-font-normal tw-text-base satoshi">
											No of Student(s)
										</h5>
										<div>
											<strong className="tw-text-main tw-font-bold tw-text-base satoshi">
												{numberWithCommas(school?.noOfStudents)}
											</strong>
										</div>
									</div>
									<div className="tw-mt-5  tw-flex tw-justify-between tw-items-center tw-border-t tw-border-secondary">
										<h5 className="tw-text-main tw-font-bold tw-text-2xl satoshi">
											Total
										</h5>
										<div>
											<strong className="tw-text-main tw-font-bold tw-text-2xl satoshi">
												NGN {numberWithCommas(Number(state?.amount).toFixed(2))}
											</strong>
										</div>
									</div>
								</>
							)}
							<div className="tw-flex tw-justify-center tw-w-1/2 tw-mx-auto tw-mt-8">
								<PrimaryBtn
									onClick={handleSubmit}
									loading={loading}
									text={
										stage === "subscribe"
											? "Continue to Subscribe"
											: "Continue to check subscription status"
									}
									width={
										"tw-bg-yellow tw-h-8 tw-w-full tw-rounded-lg tw-text-white tw-text-sm tw-font-medium kum"
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export const DetailsInput = ({ label, type, placeholder, name, onChange }) => {
	return (
		<>
			<div>
				<h5 className="tw-text-base tw-font-medium satoshi tw-text-[#3E4347]">
					{label}
				</h5>
				<input
					type={type || "text"}
					name={name}
					placeholder={placeholder}
					onChange={onChange}
					className="tw-h-12 tw-w-full tw-bg-transparent tw-pl-6 tw-rounded-lg tw-border tw-border-[#CDCDCD] tw-text-sm satoshi tw-font-normal tw-text-black placeholder:tw-font-light"
					id=""
				/>
			</div>
		</>
	);
};

export default CheckOut;
