import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Playstore from "../assets/play.svg";
import Appstore from "../assets/play2.svg";
import Micro from "../assets/micro.svg";
import BlogImg from "../assets/blog.png";
import Live from "../assets/live.png";
import Arrow from "../assets/icons/a_after.svg";
import { JoinSection, Trusted } from "./home";

const Events = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <MainSection />
      <LiveEvents />
      <Trusted />
      <Cta />
      <JoinSection />
      <Footer />
    </div>
  );
};

const HeroSection = () => {
  return (
    <section className="tw-h-screen event tw-w-full">
      <div className="container tw-relative tw-flex tw-items-center tw-h-full">
        <div>
          <div className="tw-w-40 tw-h-10 tw-bg-transparent tw-border-[0.2px] tw-border-white tw-text-xs tw-font-bold tw-text-[#D89925] tw-rounded-full tw-flex tw-justify-center tw-items-center">
            Empowering Education
          </div>
          <BigText
            color={"white"}
            align={"left"}
            css={"tw-pt-2 tw-max-w-2xl"}
            text={"Where moments become memories! "}
          />
          <p className="tw-text-base satoshi tw-font-medium tw-text-white tw-max-w-2xl">
            Our upcoming event is a celebration of innovation, creativity, and
            collaboration. Be part of the excitement
          </p>
          <div className="tw-mt-20 lg:tw-flex tw-space-y-5 lg:tw-space-y-0 tw-flex-col lg:tw-flex-row tw-gap-4">
            <img src={Appstore} alt="" className="tw-h-12 lg:tw-h-full" />
            <img src={Playstore} alt="" className="tw-h-12 lg:tw-h-full" />
          </div>
        </div>
        <img
          src={Micro}
          alt=""
          className="tw-absolute tw-bottom-4 tw-right-12"
        />
      </div>
    </section>
  );
};

const MainSection = () => {
  // const blogs = [
  //   {
  //     title: "Blog Title",
  //     desc: "A new digital HUB for Ghana’s biggest startup educational system.",
  //   },
  //   {
  //     title: "Blog Title",
  //     desc: "A new digital HUB for Ghana’s biggest startup educational system.",
  //   },
  //   {
  //     title: "Blog Title",
  //     desc: "A new digital HUB for Ghana’s biggest startup educational system.",
  //   },
  // ];
  return (
    <section className="tw-w-full tw-py-16 tw-bg-white">
      <div className="container">
        <BigText text={"RECENT BLOGS"} color={"black"} align={"center"} />
        <div className="tw-grid tw-mt-12 lg:tw-grid-cols-2 tw-gap-8 lg:tw-gap-0 tw-w-full">
          <div className="tw-h-full lg:tw-flex tw-items-center tw-justify-center">
            <div>
              <h3 className="tw-text-3xl tw-font-bold tw-text-plain satoshi">
                Blog Title
              </h3>
              <p className="tw-text-lg tw-font-normal satoshi tw-w-60 tw-text-[#141414]">
                A new digital HUB for Ghana’s biggest startup educational
                system.
              </p>
            </div>
          </div>
          <img src={BlogImg} alt="" className="" />
        </div>
        <div className="lg:tw-grid tw-hidden tw-mt-12 lg:tw-grid-cols-2 tw-gap-8 lg:tw-gap-0 tw-w-full">
          <img src={BlogImg} alt="" className="" />
          <div className="tw-h-full lg:tw-flex tw-items-center tw-justify-center">
            <div>
              <h3 className="tw-text-3xl tw-font-bold tw-text-plain satoshi">
                Blog Title
              </h3>
              <p className="tw-text-lg tw-font-normal satoshi tw-w-60 tw-text-[#141414]">
                A new digital HUB for Ghana’s biggest startup educational
                system.
              </p>
            </div>
          </div>
        </div>
        <div className="tw-grid tw-mt-12 lg:tw-grid-cols-2 tw-gap-8 lg:tw-gap-0 tw-w-full">
          <div className="tw-h-full lg:tw-flex tw-items-center tw-justify-center">
            <div>
              <h3 className="tw-text-3xl tw-font-bold tw-text-plain satoshi">
                Blog Title
              </h3>
              <p className="tw-text-lg tw-font-normal satoshi tw-w-60 tw-text-[#141414]">
                A new digital HUB for Ghana’s biggest startup educational
                system.
              </p>
            </div>
          </div>
          <img src={BlogImg} alt="" className="" />
        </div>
      </div>
    </section>
  );
};

const LiveEvents = () => {
  return (
    <section className="tw-w-full tw-py-16 tw-bg-white">
      <div className="container">
        <BigText text={"LIVE EVENTS"} color={"black"} align={"center"} />
        <p className="tw-text-center inter tw-mx-auto tw-font-normal lg:tw-text-xl tw-text-sm tw-max-w-xl tw-text-plain">
          Stay tuned for our live events. Don’t miss it. View the live events
          happening soon and plan to attend. It is going to be transforming and
          educative.
        </p>
        <div className="tw-grid tw-mt-12 lg:tw-grid-cols-2 tw-gap-8 lg:tw-gap-0 lg:tw-w-11/12 tw-mx-auto">
          <div className="tw-h-full lg:tw-px-10 tw-relative">
            <h4 className="tw-text-4xl tw-text-black tw-font-normal satoshi">
              Lorem ipsum dolor sit amet consectetur.
            </h4>
            <p className="tw-text-xl tw-font-bold tw-pt-5 tw-text-black satoshi">
              Lorem ipsum dolor sit amet consectetur. Eget volutpat in dignissim
              commodo.{" "}
            </p>
            <p className="tw-text-xl tw-font-normal tw-pt-5 tw-text-plain satoshi">
              Lorem ipsum dolor sit amet consectetur. Erat in luctus eros auctor
              tincidunt eget massa amet urna. Consequat duis cursus ac.
            </p>
            <div className="lg:tw-absolute tw-bottom-2 tw-flex tw-gap-3 tw-items-center">
              <div>
                <small className="tw-text-lg tw-font-normal tw-border-b-2 tw-border-b-[#2242B4] tw-pb-2 tw-text-black satoshi">
                  Learn more
                </small>
              </div>
              <img src={Arrow} alt="" className="" />
            </div>
          </div>
          <div>
            <img src={Live} alt="" className="" />
          </div>
        </div>
        <div className="tw-grid tw-mt-12 lg:tw-grid-cols-2 tw-gap-8 lg:tw-gap-0 lg:tw-w-11/12 tw-mx-auto">
          <div>
            <img src={Live} alt="" className="" />
          </div>
          <div className="tw-h-full lg:tw-px-10 tw-relative">
            <h4 className="tw-text-4xl tw-text-black tw-font-normal satoshi">
              Lorem ipsum dolor sit amet consectetur.
            </h4>
            <p className="tw-text-xl tw-font-bold tw-pt-5 tw-text-black satoshi">
              Lorem ipsum dolor sit amet consectetur. Eget volutpat in dignissim
              commodo.{" "}
            </p>
            <p className="tw-text-xl tw-font-normal tw-pt-5 tw-text-plain satoshi">
              Lorem ipsum dolor sit amet consectetur. Erat in luctus eros auctor
              tincidunt eget massa amet urna. Consequat duis cursus ac.
            </p>
            <div className="lg:tw-absolute tw-bottom-2 tw-flex tw-gap-3 tw-items-center">
              <div>
                <small className="tw-text-lg tw-font-normal tw-border-b-2 tw-border-b-[#2242B4] tw-pb-2 tw-text-black satoshi">
                  Learn more
                </small>
              </div>
              <img src={Arrow} alt="" className="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Cta = () => {
  return (
    <section className="tw-w-full tw-py-16 tw-mt-10 tw-mb-20 tw-bg-[#2242B4]">
      <div className="container tw-flex tw-gap-8 lg:tw-gap-0 tw-flex-col lg:tw-flex-row lg:tw-items-center tw-justify-between">
        <div>
          <h3 className="tw-text-3xl tw-font-bold tw-text-white satoshi tw-max-w-sm">
            Having troubles getting
            <br /> started? <br /> Let’s walk you through the process
          </h3>
        </div>
        <div>
          <p className="tw-text-lg tw-text-white tw-font-normal kum tw-max-w-lg">
            An invaluable tool for educators and administrators alike! Tested
            and trusted! The School Management System has simplified complex
            processes, allowing you to focus more on what matters – quality
            education.
          </p>
        </div>
        <button className="tw-bg-white tw-h-12 tw-w-36 tw-rounded kum tw-text-[#2242B4] tw-font-bold tw-text-sm">
          Contact Us
        </button>
      </div>
    </section>
  );
};

export const BigText = ({ text, align, color, css }) => {
  return (
    <div>
      <h1
        style={{
          color: color,
          textAlign: align,
        }}
        className={`lg:tw-text-5xl tw-text-2xl tw-font-bold kum ${css}`}
      >
        {text}
      </h1>
    </div>
  );
};
export default Events;
