import React from "react";
import { LogoLight } from "../logo";
import Facebook from "../../assets/icons/facebook.svg";
import Instagram from "../../assets/icons/instagram.svg";
import Linkedin from "../../assets/icons/linkedlin.svg";
import Twitter from "../../assets/icons/twitter.svg";
import Youtube from "../../assets/icons/youtube.svg";
import Plastore from "../../assets/icons/playstore.svg";
import Appstore from "../../assets/icons/appstore.svg";

const Footer = () => {
  const Ql = [
    {
      name: "About",
      url: "/about",
    },
    {
      name: "contact",
      url: "/contact",
    },
    {
      name: "Events and Blogs",
      url: "/about",
    },
  ];
  const support = [
    {
      name: "help center",
      url: "/help-center",
    },
    {
      name: "FAQS",
      url: "/faq",
    },
    {
      name: "terms & conditions",
      url: "/terms",
    },
    {
      name: "privacy policy",
      url: "/privacy",
    },
  ];
  return (
    <div>
      <footer className="tw-w-full tw-bg-[#1D2026] tw-pt-16">
        <div className="container tw-flex tw-flex-col lg:tw-flex-row tw-gap-8 lg:tw-gap-0 tw-justify-between tw-w-72">
          <div>
            <LogoLight />
            <h6 className="tw-text-sm tw-font-normal tw-text-gray-500 tw-pt-6 inter">
              Aliquam rhoncus ligula est, non pulvinar elit convallis nec. Donec
              mattis odio at.
            </h6>
            <div className="tw-mt-6 tw-flex tw-gap-3 tw-items-center">
              <img src={Facebook} alt="" className="" />
              <img src={Instagram} alt="" className="" />
              <img src={Linkedin} alt="" className="" />
              <img src={Twitter} alt="" className="" />
              <img src={Youtube} alt="" className="" />
            </div>
          </div>
          <div className="">
            <h6 className="tw-text-sm tw-uppercase tw-text-white tw-font-medium inter">
              Quick links
            </h6>
            <div className="tw-mt-4 tw-space-y-3">
              {Ql?.map((it) => (
                <h6 className="tw-text-sm tw-font-normal inter tw-text-[#8C94A3]">
                  {it?.name}
                </h6>
              ))}
            </div>
          </div>
          <div className="">
            <h6 className="tw-text-sm tw-uppercase tw-text-white tw-font-medium inter">
              Support
            </h6>
            <div className="tw-mt-4 tw-space-y-3">
              {support?.map((it) => (
                <h6 className="tw-text-sm tw-font-normal inter tw-text-[#8C94A3]">
                  {it?.name}
                </h6>
              ))}
            </div>
          </div>
          <div>
            <h6 className="tw-text-sm tw-uppercase tw-text-white tw-font-medium inter">
              download our app
            </h6>
            <div className="tw-space-y-5">
              <img src={Appstore} alt="" className="" />
              <img src={Plastore} alt="" className="" />
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
