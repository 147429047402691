import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { TOKEN } from "../Config";

const AuthStore = set => ({
	token: localStorage.getItem(TOKEN) || null,
	user: null,
	isAuth: false,
	loading: false,
	isRegistered: false,
	isLoggedIn: false,
	isUpdated: false,
	isPassword: null,
	institutionType: null,
	school: null,
	plans: null,
	login: payload => {
		localStorage.setItem(TOKEN, payload?.token);
		set(
			state => ({
				user: payload?.data || payload,
				token: payload?.token,
				isLoggedIn: true,
			}),
			false,
			"login"
		);
	},
	setUser: payload => {
		set(state => ({ isUpdated: true, user: payload?.data }), false, "setUser");
	},
	setSchool: payload => {
		set(state => ({ school: payload?.data || payload }), false, "setSchool");
	},
	setPlans: payload => {
		set(state => ({ plans: payload?.data || payload }), false, "setPlans");
	},
	setInstitution: payload => {
		set(
			state => ({ institutionType: payload?.data || payload }),
			false,
			"setInstitution"
		);
	},
	getUser: payload => {
		if (payload?.token) {
			localStorage.setItem(TOKEN, payload?.token);
		}
		set(
			state => ({
				user: payload?.data || payload,
				isAuth: payload?.data || payload ? true : false,
				loading: false,
			}),
			false,
			"getUser"
		);
	},
	getUserFail: () => {
		set(state => ({ isAuth: false, loading: false }));
	},
	getUserLoading: () => {
		set(state => ({ loading: true }));
	},
	setPassword: () => {
		set(state => ({ isPassword: true }));
	},
	setUserFail: () => {
		set(state => ({
			isUpdated: false,
			isLoggedIn: false,
			isRegistered: false,
			isPassword: false,
		}));
	},
	logout: () => {
		localStorage.removeItem(TOKEN);
		localStorage.clear();
		set(
			state => ({
				isAuth: false,
				user: null,
				token: null,
				role: null,
			}),
			false,
			"logout"
		);
	},
});

const useAuthStore = create(
	devtools(
		persist(AuthStore, {
			name: "cephas-sms",
		})
	)
);

export default useAuthStore;
