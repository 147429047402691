import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import { Controller } from "react-hook-form";

const Inputs = ({ placeholder, label, onChange, type, name, css }) => {
	return (
		<div>
			<div>
				{" "}
				<strong className="tw-text-base satoshi tw-font-medium tw-text-[#3e4347]">
					{label}
				</strong>
			</div>
			<input
				type={type || "text"}
				name={name}
				onChange={onChange}
				placeholder={placeholder}
				className={`tw-w-full ${css} tw-pl-6 tw-h-14 tw-text-base tw-text-plain tw-font-normal satoshi tw-border tw-border-[#E0E0E0]`}
				id=""
			/>
		</div>
	);
};

export const TextInputs = ({
	placeholder,
	label,
	onChange,
	type,
	name,
	css,
	value,
	...restProps
}) => {
	return (
		<div>
			<div>
				{" "}
				<strong className="tw-text-base satoshi tw-font-medium tw-text-[#3e4347]">
					{label}
				</strong>
			</div>
			{type === "textarea" ? (
				<textarea
					value={value}
					onChange={onChange}
					name={name}
					className={`${css} lg:tw-w-[440px] tw-rounded-2xl tw-w-full tw-mt-2  tw-pl-6 tw-h-14 tw-text-base tw-text-plain tw-font-normal satoshi tw-border tw-border-[#E0E0E0]`}
					{...restProps}
					placeholder={placeholder}
					style={{ resize: "none", height: "10rem" }}
				/>
			) : (
				<input
					value={value}
					type={type || "text"}
					name={name}
					onChange={onChange}
					placeholder={placeholder}
					className={`${css} lg:tw-w-[440px] tw-rounded-2xl tw-w-full tw-mt-2  tw-pl-6 tw-h-14 tw-text-base tw-text-plain tw-font-normal satoshi tw-border tw-border-[#E0E0E0]`}
					id=""
					{...restProps}
				/>
			)}
		</div>
	);
};

export const PasswordInputs = ({
	placeholder,
	label,
	onChange,
	type,
	name,
	css,
}) => {
	const [show, setShow] = useState(false);
	return (
		<div>
			<div>
				{" "}
				<strong className="tw-text-base satoshi tw-font-medium tw-text-[#3e4347]">
					{label}
				</strong>
			</div>
			<div
				className={`${css} lg:tw-w-[440px] tw-rounded-2xl tw-mt-2  tw-relative tw-h-14 tw-text-base tw-text-plain tw-font-normal satoshi tw-border tw-border-[#E0E0E0]`}>
				<input
					type={show ? "text" : "password"}
					name={name}
					onChange={onChange}
					placeholder={placeholder}
					className={`tw-h-full tw-pl-6 tw-w-full focus:tw-border-none`}
					id=""
				/>
				<div
					onClick={() => setShow(!show)}
					className="tw-absolute tw-cursor-pointer tw-top-5 tw-right-4">
					{show ? <FaEyeSlash /> : <FaEye />}
				</div>
			</div>
		</div>
	);
};

export const Select = ({ name, onChange, value, options, label }) => {
	return (
		<div>
			<div>
				{" "}
				<strong className="tw-text-base satoshi tw-font-medium tw-text-[#3e4347]">
					{label}
				</strong>
			</div>
			<select
				className="tw-w-full tw-pl-6 tw-h-14 tw-text-base tw-text-plain tw-font-normal satoshi tw-border tw-border-[#E0E0E0]"
				onChange={onChange}
				value={value}
				name={name}
				id="">
				<option value="" selected>
					Choose Value
				</option>
				{options?.map((it, i) => (
					<option
						key={i}
						value={
							it?._id || it?.code || it?.name || it?.title || it?.value || it
						}>
						{name === "stateOfResidence" ? (
							<>
								{it?.name}: {it?.capital}
							</>
						) : (
							it?.title || it?.value || it?.name || it
						)}
					</option>
				))}
			</select>
		</div>
	);
};

export const HooksForm = ({
	methods,
	name,
	rules,
	type,
	placeholder,
	label,
	css,
	required,
	...restProps
}) => {
	return (
		<Controller
			name={name}
			control={methods.control}
			rules={rules}
			render={({ field: { onChange, name, value } }) => (
				<fieldset className="">
					<div>
						{type === "password" ? (
							<PasswordInputs
								label={label}
								value={value}
								name={name}
								onChange={onChange}
								{...restProps}
							/>
						) : (
							<TextInputs
								name={name}
								value={value}
								required={required}
								type={type}
								placeholder={placeholder}
								label={label}
								css={css}
								onChange={onChange}
								{...restProps}
							/>
						)}
						<div className="tw-w-full lg:tw-w-[440px]">
							{methods.formState.errors[name] && (
								<span className="tw-text-xs tw-font-bold tw-text-red-600">
									{methods.formState.errors[name].message}
								</span>
							)}
						</div>
					</div>
				</fieldset>
			)}
		/>
	);
};

export const PrimaryBtn = ({
	text,
	bg,
	color,
	onClick,
	width,
	disabled,
	height,
	...restProps
}) => {
	return (
		<div>
			<button
				disabled={disabled || restProps?.loading}
				onClick={onClick}
				style={{
					background: bg,
					color: color,
				}}
				className={`${height || "tw-h-10"} ${width || "tw-min-w-24"} ${
					(disabled || restProps?.loading) &&
					"tw-cursor-not-allowed tw-bg-opacity-40"
				} tw-px-5 tw-rounded-lg tw-text-base satoshi tw-font-medium`}>
				{text}{" "}
				{restProps?.loading && (
					<ClipLoader color={restProps?.loadCss || "white"} size={12} />
				)}
			</button>
		</div>
	);
};

export const LoadMore = ({ handleLoadMore, next, loading }) => {
	return (
		<>
			{!next ? (
				""
			) : (
				<PrimaryBtn
					onClick={handleLoadMore}
					text={loading ? "Loading..." : "Load More"}
					loading={loading}
					css="tw-mx-auto"
					type={"button"}
					buttonType={"primary"}
					width={"w"}
				/>
			)}
		</>
	);
};

export const ModalContainer = ({ children }) => {
	return (
		<div
			// onClick={e => e.target === e.currentTarget && handleClose()}
			className="tw-fixed tw-inset-0 tw-z-50 tw-bg-gray-600 tw-bg-opacity-75 tw-transition-opacity tw-duration-700 tw-flex tw-items-center tw-justify-center tw-min-h-screen">
			<div className="tw-w-full tw-max-w-md tw-bg-white tw-rounded-lg tw-shadow-2xl tw-transition-all tw-ease-in-out tw-duration-700 tw-p-4">
				{children}
			</div>
		</div>
	);
};


export default Inputs;
