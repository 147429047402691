import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { BigText } from "./event";
import Playstore from "../assets/play.svg";
import Appstore from "../assets/play2.svg";
import Phone from "../assets/phone.png";
import Inputs, { Select } from "../components/inputs";
import Map from "../assets/map.svg";
import PhoneIcon from "../assets/icons/phone.svg";
import Fax from "../assets/icons/fax.svg";
import Email from "../assets/icons/email.svg";
import { FaLongArrowAltRight } from "react-icons/fa";
import HowImg from "../assets/how.png";
import { JoinSection, TestimonialSection, Trusted } from "./home";

const Contact = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <MainSection />
      <How />
      <TestimonialSection />
      <Trusted />
      <JoinSection />
      <Footer />
    </div>
  );
};

const HeroSection = () => {
  return (
    <section className="tw-w-full tw-h-screen contact">
      <div className="container tw-relative tw-flex tw-items-center tw-h-full">
        <div>
          <div className="tw-w-40 tw-h-10 tw-bg-transparent tw-border-[0.2px] tw-border-white tw-text-xs tw-font-bold tw-text-[#D89925] tw-rounded-full tw-flex tw-justify-center tw-items-center">
            Empowering Education
          </div>
          <BigText
            color={"white"}
            align={"left"}
            css={"tw-pt-2 tw-max-w-3xl"}
            text={"Get in Touch with Our Customer Care Center"}
          />
          <p className="tw-text-base satoshi tw-font-medium tw-text-white tw-max-w-xl">
            We love hearing from you! Reach out for support, inquiries, or just
            to share your thoughts
          </p>
          <div className="tw-mt-20 lg:tw-flex tw-space-y-5 lg:tw-space-y-0 tw-flex-col lg:tw-flex-row tw-gap-4">
            <img src={Appstore} alt="" className="tw-h-12 lg:tw-h-full" />
            <img src={Playstore} alt="" className="tw-h-12 lg:tw-h-full" />
          </div>
        </div>
        <img
          src={Phone}
          alt=""
          className="tw-absolute tw-right-8 tw-bottom-16"
        />
      </div>
    </section>
  );
};

const MainSection = () => {
  const Arr = [
    {
      icon: PhoneIcon,
      title: "Phone",
      value: "+234 706 326 0342",
    },
    {
      icon: Fax,
      title: "FAX",
      value: "7060342",
    },
    {
      icon: Email,
      title: "Email",
      value: "+info@lskdfjsd.com",
    },
  ];
  return (
    <section className="tw-w-full tw-py-20 tw-bg-white">
      <div className="container">
        <BigText align={"center"} text={"Contact Us"} color={"black"} />
        <p className="tw-text-lg tw-text-plain tw-pt-1 tw-font-normal satoshi tw-text-center tw-max-w-2xl tw-mx-auto">
          import Playstore from "../assets/play.svg"; We are always open to
          discuss new value-adding partnerships. Do reach out if you are an
          exchange or a project looking for liquidity; an algorithmic trader or
          a software developer looking to improve the markets with us or just
          have a great idea you can’t wait to share with us!
        </p>
        <div className="tw-flex tw-mt-10 tw-justify-center">
          <button className="tw-bg-[#2242B4] tw-w-44 tw-h-14 tw-rounded-full tw-text-lg tw-font-medium inter tw-text-white tw-uppercase">
            get in touch
          </button>
        </div>
        <div className="tw-mt-16 tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-gap-28">
          <div className="lg:tw-w-[60%]">
            <h2 className="tw-text-4xl tw-font-bold satoshi tw-text-black">
              Get in <span className="tw-text-yellow">Touch</span>
            </h2>
            <p className="tw-text-base tw-font-normal tw-max-w-lg tw-text-black satoshi">
              Enim tempor eget pharetra facilisis sed maecenas adipiscing. Eu
              leo molestie vel, ornare non id blandit netus.
            </p>
            <form action="" className="tw-mt-6">
              <div className="tw-space-y-4">
                <Inputs placeholder={"Name"} />
                <Inputs placeholder={"Email"} />
                <Inputs placeholder={"Phone number"} />
                <Select />
                <button className="tw-bg-[#FFC000] tw-h-14 tw-w-full tw-text-base satoshi tw-font-bold tw-text-white">
                  SEND
                </button>
              </div>
            </form>
            <div className="tw-mt-8 tw-grid lg:tw-grid-cols-3 tw-gap-6">
              {Arr?.map((it, i) => (
                <div key={i} className="tw-flex tw-items-center tw-gap-5">
                  <img src={it?.icon} alt="" className="" />
                  <div>
                    <div>
                      <strong className="tw-text-xs tw-uppercase tw-font-normal tw-text-black satoshi ">
                        {it?.title}
                      </strong>
                    </div>
                    <div>
                      <strong className="tw-text-xs tw-uppercase tw-text-yellow tw-font-normal satoshi ">
                        {it?.value}
                      </strong>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="">
            <img src={Map} alt="" className="" />
          </div>
        </div>
      </div>
    </section>
  );
};

const How = () => {
  const Arr = [
    {
      value: "1468%",
      name: "ROI",
    },
    {
      value: "25%",
      name: "Growth",
    },
    {
      value: "20,000",
      name: "Users",
    },
  ];
  return (
    <section className="tw-bg-[#F2FDFF] tw-py-20">
      <div className="container tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-gap-16">
        <div className="lg:tw-w-[60%]">
          <BigText
            css={"lg:tw-text-6xl tw-uppercase"}
            align={"left"}
            text={"HOW IT WORKS"}
          />
          <p className="lg:tw-text-xl satoshi tw-font-medium tw-text-[#58595D]">
            Lorem ipsum dolor sit amet consectetur. Amet in a vitae in eget
            pharetra. Egestas tincidunt nunc diam in sed sed integer.
          </p>
          <div className="tw-mt-8 tw-flex tw-justify-between">
            {Arr?.map((it) => (
              <div className="">
                <strong className="tw-text-[#2E2F35] tw-font-bold inter tw-text-3xl">
                  {it?.value}
                </strong>
                <div>
                  <small className="tw-text-sm tw-font-medium tw-text-[#58595D] inter">
                    {it?.name}
                  </small>
                </div>
              </div>
            ))}
          </div>
          <div className="tw-mt-10 tw-h-12 tw-w-48 tw-bg-yellow tw-rounded-full tw-flex tw-justify-center tw-gap-4 tw-items-center">
            <div>
              <small className="satoshi tw-font-normal tw-text-lg tw-text-black">
                Sign up free
              </small>
            </div>
            <FaLongArrowAltRight />
          </div>
        </div>
        <div className="">
          <img src={HowImg} alt="" className="" />
        </div>
      </div>
    </section>
  );
};

export default Contact;
