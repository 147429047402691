import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Tick1 from "../../assets/icons/pricing-tick1.svg";
import Tick2 from "../../assets/icons/pricing-tick2.svg";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../data/Stores/AuthStore";
import { numberWithCommas } from "../../data/useGenFetcher";

const Pricing = () => {
	return (
		<div>
			<Header />
			<MainPricingSection />
			<Footer />
		</div>
	);
};

const MainPricingSection = () => {
	const navigate = useNavigate();
	// const PricingArr = [
	// 	{
	// 		type: "free",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus odio pellentesque pellentesque a. Amet",
	// 		price: "0",
	// 		features: [
	// 			"For 1-1 students in a school",
	// 			"Student Portal",
	// 			"Results management",
	// 			"Class administration",
	// 			"exams and assessment",
	// 		],
	// 	},
	// 	{
	// 		type: "standard",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus odio pellentesque pellentesque a. Amet",
	// 		price: "15",
	// 		features: [
	// 			"More than 100 students",
	// 			"Student Portal",
	// 			"Results management",
	// 			"Class administration",
	// 			"exams and assessment",
	// 			"CBT system",
	// 			"DBM system",
	// 			"resource management",
	// 		],
	// 	},
	// 	{
	// 		type: "advanced",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus odio pellentesque pellentesque a. Amet",
	// 		price: "15",
	// 		features: [
	// 			"More than 100 students",
	// 			"Student Portal",
	// 			"Results management",
	// 			"Class administration",
	// 			"exams and assessment",
	// 			"CBT system",
	// 			"DBM system",
	// 			"resource management",
	// 			"grading system",
	// 			"LMS",
	// 			"admission processing",
	// 			"transcript processing",
	// 			"accounts & finances",
	// 		],
	// 	},
	// 	{
	// 		type: "premium",
	// 		desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus odio pellentesque pellentesque a. Amet",
	// 		price: "15",
	// 		features: [
	// 			"More than 100 students",
	// 			"Student Portal",
	// 			"Results management",
	// 			"Class administration",
	// 			"exams and assessment",
	// 			"CBT system",
	// 			"DBM system",
	// 			"resource management",
	// 			"grading system",
	// 			"LMS",
	// 			"admission processing",
	// 			"transcript processing",
	// 			"accounts & finances",
	// 			"HRMS",
	// 			"boarding management",
	// 			"meal management",
	// 			"payroll",
	// 			"transportation management",
	// 			"sales management",
	// 		],
	// 	},
	// ];

	const { plans, isAuth } = useAuthStore();

	return (
		<section className="tw-w-full tw-mt-8 tw-py-20 tw-bg-white">
			<div className="container">
				<h2 className="lg:tw-text-5xl tw-text-xl lg:tw-text-center tw-font-bold kum tw-text-black">
					CESMAS PLANS AND PRICING
				</h2>
				<h5 className="lg:tw-text-4xl tw-text-[15px] tw-font-normal kum lg:tw-text-center tw-text-black">
					Choose A Plan
				</h5>
				<div className="tw-mt-20 tw-grid lg:tw-grid-cols-4 tw-gap-6">
					{plans?.docs?.map((it, i) => (
						<div
							key={i}
							style={{
								boxShadow: "0px 4px 4px 0px #00000040",
							}}
							className={`tw-h-[1000px] tw-w-full tw-p-5 tw-rounded-xl tw-relative ${
								it?.title === "standard" ? "tw-bg-[#2242B4]" : "tw-bg-white"
							}`}>
							<button
								className={`tw-px-4 tw-h-9 tw-text-base kum tw-font-semibold tw-capitalize tw-rounded ${
									it?.title === "standard"
										? "tw-bg-black tw-text-[#FFC000]"
										: "tw-bg-[#EDF5FF] tw-text-blue"
								}`}>
								{it?.title}
							</button>
							<p
								className={`tw-text-[13px] tw-mt-6 tw-font-normal ${
									it?.title === "standard"
										? "tw-text-white"
										: "tw-text-[#667085] "
								} kum`}>
								{it?.desc}
							</p>
							<div
								className={`tw-py-2 tw-border-b ${
									it?.title === "standard"
										? "tw-border-b-white"
										: "tw-border-b-[#AAAAAA]"
								}`}>
								<h3
									className={`tw-text-2xl kum tw-font-bold ${
										it?.title === "standard"
											? "tw-text-white"
											: "tw-text-[#1E2024]"
									}`}>
									NGN {numberWithCommas(Number(it?.amount).toFixed(2))}{" "}
									<span
										className={`tw-text-base tw-font-normal kum ${
											it?.title === "standard"
												? "tw-text-white"
												: "tw-text-[#667085]"
										}`}>
										/{it?.frequency}
									</span>
								</h3>
							</div>
							<div className="tw-mt-5 tw-space-y-3">
								{it?.features?.map((feat, idx) => (
									<div key={idx} className="tw-flex tw-gap-4 tw-items-center">
										<div>
											{it?.title === "standard" ? (
												<img src={Tick2} alt="" className="" />
											) : (
												<img src={Tick1} alt="" className="" />
											)}
										</div>
										<strong
											className={`tw-text-[15px] tw-capitalize tw-font-bold kum ${
												it?.title === "standard"
													? "tw-text-white"
													: "tw-text-[#667085]"
											}`}>
											{feat?.title || feat?.name || feat}
										</strong>
									</div>
								))}
							</div>
							<div className="tw-w-3/4 tw-absolute tw-mx-auto tw-left-0 tw-right-0 tw-bottom-5">
								<div className="tw-flex tw-justify-center tw-w-full">
									<button
										onClick={() =>
											navigate(isAuth ? "/pricing/check-out" : "/login", {
												state: isAuth
													? it
													: {
															next: "/pricing/check-out",
															plan: it,
													  },
											})
										}
										className="tw-bg-yellow tw-h-8 tw-w-full tw-rounded-lg tw-text-white tw-text-sm tw-font-medium kum">
										Choose
									</button>
								</div>
							</div>
						</div>
					))}
					{/* {PricingArr?.map((it, i) => (
						<div
							key={i}
							style={{
								boxShadow: "0px 4px 4px 0px #00000040",
							}}
							className={`tw-h-[1000px] tw-w-full tw-p-5 tw-rounded-xl tw-relative ${
								it?.type === "standard" ? "tw-bg-[#2242B4]" : "tw-bg-white"
							}`}>
							<button
								className={`tw-px-4 tw-h-9 tw-text-base kum tw-font-semibold tw-capitalize tw-rounded ${
									it?.type === "standard"
										? "tw-bg-black tw-text-[#FFC000]"
										: "tw-bg-[#EDF5FF] tw-text-blue"
								}`}>
								{it?.type}
							</button>
							<p
								className={`tw-text-[13px] tw-mt-6 tw-font-normal ${
									it?.type === "standard"
										? "tw-text-white"
										: "tw-text-[#667085] "
								} kum`}>
								{it?.desc}
							</p>
							<div
								className={`tw-py-2 tw-border-b ${
									it?.type === "standard"
										? "tw-border-b-white"
										: "tw-border-b-[#AAAAAA]"
								}`}>
								<h3
									className={`tw-text-4xl kum tw-font-semibold ${
										it?.type === "standard"
											? "tw-text-white"
											: "tw-text-[#1E2024]"
									}`}>
									${it?.price}{" "}
									<span
										className={`tw-text-base tw-font-normal kum ${
											it?.type === "standard"
												? "tw-text-white"
												: "tw-text-[#667085]"
										}`}>
										/Month
									</span>
								</h3>
							</div>
							<div className="tw-mt-5 tw-space-y-3">
								{it?.features?.map((feat, idx) => (
									<div key={idx} className="tw-flex tw-gap-4 tw-items-center">
										<div>
											{it?.type === "standard" ? (
												<img src={Tick2} alt="" className="" />
											) : (
												<img src={Tick1} alt="" className="" />
											)}
										</div>
										<strong
											className={`tw-text-[15px] tw-capitalize tw-font-bold kum ${
												it?.type === "standard"
													? "tw-text-white"
													: "tw-text-[#667085]"
											}`}>
											{feat}
										</strong>
									</div>
								))}
							</div>
							<div className="tw-w-3/4 tw-absolute tw-mx-auto tw-left-0 tw-right-0 tw-bottom-5">
								<div className="tw-flex tw-justify-center tw-w-full">
									<button
										onClick={() => navigate("/pricing/check-out")}
										className="tw-bg-yellow tw-h-8 tw-w-full tw-rounded-lg tw-text-white tw-text-sm tw-font-medium kum">
										Choose
									</button>
								</div>
							</div>
						</div>
					))} */}
				</div>
			</div>
		</section>
	);
};

export default Pricing;
