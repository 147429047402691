import axios from "axios";
import { toast } from "react-toastify";
import { SetAuthToken, TOKEN } from "./Config";
import useErrorStore from "./Stores/ErrrorStore";
import useAuthStore from "./Stores/AuthStore";

export const apiCall = async ({
	type,
	url,
	data,
	getter,
	headers,
	noToast,
}) => {
	try {
		let res;
		if (type === "get")
			res = await axios.get(url, {
				headers: {
					...headers,
				},
			});
		if (type === "post")
			res = await axios.post(
				url,
				{ ...data },
				{
					headers: {
						...headers,
					},
				}
			);
		if (type === "put")
			res = await axios.put(
				url,
				{ ...data },
				{
					headers: {
						...headers,
					},
				}
			);
		if (type === "patch")
			res = await axios.patch(
				url,
				{ ...data },
				{
					headers: {
						...headers,
					},
				}
			);
		if (type === "delete")
			res = await axios.delete(url, {
				headers: {
					...headers,
				},
			});
		if (type === "file") {
			res = await axios.post(
				`/api/v1/file`,
				{ ...data },
				{
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
		}

		let response = res?.data;
		if (!["file", "get", "patch"]?.includes(type))
			if (!noToast) toast.success(res?.data?.message);
		if (getter) getter(response);
		return { response };
	} catch (err) {
		if (err) console.log({ error: err.response?.data, err });
		if (err?.response?.status === 429) toast.error(err?.response?.data);
		if (type && type !== "get") {
			let error = err.response?.data?.error;
			if (error && error?.length > 1) {
				return { errArr: error };
			} else {
				let errMsg =
					err?.response?.data?.message ||
					err?.response?.data?.error?.[0]?.message ||
					err?.response?.data?.error?.[0]?.msg ||
					err?.message;
				return { errMsg };
			}
		}
	}
};

export let numberWithCommas = x => {
	return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : x;
};

const useGenFetcher = () => {
	let { getErrorText, clearErrors } = useErrorStore(),
		{
			getUser,
			getUserFail,
			getUserLoading,
			setInstitution,
			setPlans,
			setSchool,
		} = useAuthStore(),
		loadUser = async () => {
			let token = localStorage.getItem(TOKEN);
			if (token) SetAuthToken(token);

			getUserLoading();
			clearErrors();
			try {
				let res = await axios.get(`/api/v1/user`);
				// console.log({ res: res?.data });
				if (res?.data?.data?.privilege === "school-admin") {
					getUser(res.data);
					apiCall({
						type: "get",
						url: "/api/v1/school/workspace",
						getter: setSchool,
						headers: {
							"school-source": res?.data?.data?.school,
						},
					});
				} else {
					getUserFail();
					getErrorText("Unauthorized User, Access denied");
				}
			} catch (err) {
				if (err) console.log({ error: err.response?.data, err });
				if (err?.response?.status === 429) toast.error(err?.response?.data);
				getUserFail();
				getErrorText(
					err?.response?.data?.message ||
						err?.response?.data?.error?.[0]?.message ||
						""
				);
			}
			apiCall({
				type: "get",
				url: "/api/v1/schooltype",
				getter: setInstitution,
			});
			apiCall({ type: "get", url: "/api/v1/plan", getter: setPlans });
		};
	return { loadUser };
};

export const getUrl = ({ addition = "" }) => {
	// let hostname = window.location.hostname,
	// 	newUrl = "";
	// if (hostname?.startsWith("www")) hostname = hostname?.replace("www.", "");

	// if (!hostname?.includes(".vercel.app")) {
	// 	// Split the hostname by '.' to get subdomain
	// 	const parts = hostname.split(".");

	// 	// Check if there's a subdomain (more than 2 parts in the hostname)
	// 	console.log({ parts });
	// 	if (parts.length === (hostname?.includes("localhost") ? 1 : 2)) {
	// 		// Subdomain is the first part of the hostname

	// 		newUrl = `${window?.location?.protocol}//${
	// 			addition ? `${addition}.` : ""
	// 		}${
	// 			window.location?.hostname?.startsWith("www")
	// 				? window.location?.hostname?.replace("www.", "")
	// 				: window.location?.hostname
	// 		}${window.location.port ? `:${window.location.port}` : ""}`;
	// 	}
	// }

	let hostname = window.location.hostname,
		newUrl = "";
	if (hostname?.startsWith("www")) hostname = hostname?.replace("www.", "");

	if (!hostname?.includes(".vercel.app")) {
		// Split the hostname by '.' to get subdomain
		const parts = hostname.split(".");

		// Check if there's a subdomain (more than 2 parts in the hostname)
		console.log({ parts });
		if (parts.length === (hostname?.includes("localhost") ? 1 : 2)) {
			// Subdomain is the first part of the hostname

			newUrl = `${window?.location?.protocol}//${
				addition ? `${addition}.` : ""
			}${
				window.location?.hostname?.startsWith("www")
					? window.location?.hostname?.replace("www.", "")
					: window.location?.hostname
			}${window.location.port ? `:${window.location.port}` : ""}`;
		}
	}
	return newUrl;
};

export default useGenFetcher;
