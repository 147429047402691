import React from "react";
import Header from "../components/header";
import Solution from "../assets/asset.png";
import Walmart from "../assets/walmart.svg";
import Cisco from "../assets/cisco.png";
import Volvo from "../assets/volvo.svg";
import { FaArrowRight } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Mousewheel } from "swiper/modules";
import "swiper/css";
import Quote from "../assets/quote.svg";
import Icons from "../assets/message.svg";
import Join1 from "../assets/join1.png";
import Join2 from "../assets/join2.png";
import Footer from "../components/footer";

const Home = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <WhySection />
      <Trusted />
      <TestimonialSection />
      <Features />
      <JoinSection />
      <Footer />
    </div>
  );
};

const HeroSection = () => {
  return (
    <section className="tw-h-screen hero tw-w-full">
      <div className="container tw-flex tw-justify-center tw-items-center tw-w-full tw-h-full">
        <div>
          <div className="tw-w-40 tw-h-10 tw-mx-auto tw-bg-transparent tw-border-[0.2px] tw-border-white tw-text-xs tw-font-bold tw-text-[#D89925] tw-rounded-full tw-flex tw-justify-center tw-items-center">
            Empowering Education
          </div>
          <h1 className="lg:tw-text-5xl tw-text-2xl tw-font-bold tw-text-center lg:tw-max-w-4xl tw-text-white kum lg:tw-leading-[59.53px]">
            Experience a seamless blend of technology and academia with our
            cutting-edge SMS
          </h1>
          <h2 className="tw-text-base tw-font-normal tw-mx-auto tw-max-w-lg tw-text-center satoshi tw-text-white">
            Elevate your school's efficiency with our intuitive School
            Management System. Unlock the future of learning
          </h2>
          <div className="tw-mt-10 tw-flex tw-justify-center">
            <button className="tw-h-10 tw-w-32 tw-bg-yellow tw-rounded-full tw-text-base tw-font-bold tw-text-white satoshi">
              Get Started
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

const WhySection = () => {
  const colors = ["#FDF3EA", "#E0F8F2", "#EEECFF", "#FAEDED", "#F1F1F1"];
  const Arr = [
    {
      img: Solution,
      title: "Solution 1",
      desc: "Native integrations provide seamless segmentation, automation & tracking. No clunky spreadsheets",
    },
    {
      img: Solution,
      title: "Solution 1",
      desc: "Native integrations provide seamless segmentation, automation & tracking. No clunky spreadsheets",
    },
    {
      img: Solution,
      title: "Solution 1",
      desc: "Native integrations provide seamless segmentation, automation & tracking. No clunky spreadsheets",
    },
    {
      img: Solution,
      title: "Solution 1",
      desc: "Native integrations provide seamless segmentation, automation & tracking. No clunky spreadsheets",
    },
    {
      img: Solution,
      title: "Solution 1",
      desc: "Native integrations provide seamless segmentation, automation & tracking. No clunky spreadsheets",
    },
    {
      img: Solution,
      title: "Solution 1",
      desc: "Native integrations provide seamless segmentation, automation & tracking. No clunky spreadsheets",
    },
  ];
  return (
    <section className="tw-w-full tw-bg-white tw-py-20">
      <div className="container">
        <h2 className="lg:tw-text-5xl tw-text-2xl tw-font-bold tw-text-[#2E2F35] inter tw-text-center">
          WHY EDU SMS?
        </h2>
        <p className="tw-text-center tw-pt-4 tw-max-w-3xl tw-mx-auto tw-font-normal satoshi lg:tw-text-base tw-text-sm tw-text-plain">
          {" "}
          <span className="tw-font-bold">
            Smart Solutions for Smart Schools.
          </span>{" "}
          Transform the way you manage education. Our School Management System
          makes administration a breeze.
        </p>
        <div className="tw-mt-12 tw-grid lg:tw-grid-cols-3 tw-gap-8">
          {Arr?.map((it, i) => (
            <div
              style={{
                background: colors[i % colors.length],
              }}
              className="lg:tw-h-[424px] tw-rounded-lg tw-p-6"
            >
              <img src={it?.img} alt="" srcset="" className="tw-w-full" />
              <h3 className="tw-text-3xl tw-font-bold tw-text-center tw-pt-6 satoshi tw-text-[#2E2F35]">
                {it?.title}
              </h3>
              <p className="tw-text-base tw-pt-3 tw-font-medium satoshi tw-text-center tw-text-[#58595D]">
                {it?.desc}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export const Trusted = () => {
  return (
    <section className="tw-bg-white tw-w-full tw-py-16">
      <div className="container">
        <h2 className="lg:tw-text-5xl tw-text-2xl tw-font-bold tw-text-[#2E2F35] inter tw-text-center">
          Trusted by company like
        </h2>
        <p className="tw-text-center tw-pt-4 tw-max-w-3xl tw-mx-auto tw-font-normal satoshi tw-text-base tw-text-plain">
          {" "}
          <span className="tw-font-bold">
            Smart Solutions for Smart Schools.
          </span>{" "}
          Transform the way you manage education. Our School Management System
          makes administration a breeze.
        </p>
        <div className="tw-mt-10 lg:tw-flex lg:tw-flex-row tw-grid tw-grid-cols-2 tw-w-5/6 tw-mx-auto lg:tw-w-full lg:tw-justify-center tw-gap-6">
          <img src={Walmart} alt="walmart" className="tw-h-12 lg:tw-h-full" />
          <img src={Cisco} alt="cisco" className="tw-h-12 lg:tw-h-full" />
          <img src={Volvo} alt="volvo" className="tw-h-12 lg:tw-h-full" />
        </div>
      </div>
      <div className="container tw-mt-16 tw-bg-[#2242B4] tw-rounded-2xl tw-py-12">
        <h2 className="tw-text-5xl tw-font-bold tw-text-white satoshi tw-text-center">
          Join 45M+ users today
        </h2>
        <p className="tw-pt-4 tw-text-lg tw-max-w-lg tw-mx-auto tw-text-center tw-font-normal satoshi tw-text-white tw-text-opacity-80">
          Start for free — upgrade anytime. Discover a new era of education
          management. Embrace efficiency, embrace excellence.
        </p>
        <div className="tw-mt-8 tw-flex tw-justify-center">
          <button className="tw-bg-white tw-border tw-rounded-full tw-border-yellow tw-h-12 tw-w-44 tw-flex tw-justify-center tw-items-center tw-text-lg tw-font-normal tw-text-black inter">
            <small>Sign up free</small>
            <FaArrowRight />
          </button>
        </div>
      </div>
    </section>
  );
};

export const TestimonialSection = () => {
  const Arr = [
    {
      name: "Debbie A. Aquila",
      text: "From enrollment to report cards, the School Management System has brought a remarkable level of efficiency to our school. It's a tool that has truly made a positive impact on our academic landscape",
    },
    {
      name: "Debbie A. Aquila",
      text: "From enrollment to report cards, the School Management System has brought a remarkable level of efficiency to our school. It's a tool that has truly made a positive impact on our academic landscape",
    },
    {
      name: "Debbie A. Aquila",
      text: "From enrollment to report cards, the School Management System has brought a remarkable level of efficiency to our school. It's a tool that has truly made a positive impact on our academic landscape",
    },
    {
      name: "Debbie A. Aquila",
      text: "From enrollment to report cards, the School Management System has brought a remarkable level of efficiency to our school. It's a tool that has truly made a positive impact on our academic landscape",
    },
  ];
  return (
    <section className="tw-bg-white tw-py-10 tw-w-full">
      <div className="container">
        {" "}
        <h2 className="lg:tw-text-5xl tw-text-2xl tw-font-bold tw-text-[#2E2F35] inter tw-text-center">
          Hear Our Customers Speak
        </h2>
        <p className="tw-text-center tw-pt-4 tw-max-w-3xl tw-mx-auto tw-font-normal satoshi lg:tw-text-base tw-text-sm tw-text-plain">
          {" "}
          <span className="tw-font-bold">
            Smart Solutions for Smart Schools.
          </span>{" "}
          Transform the way you manage education. Our School Management System
          makes administration a breeze.
        </p>
        <div className="tw-mt-12 tw-w-full">
          <div className="tw-grid tw-justify-center lg:tw-hidden tw-gap-6">
            {Arr?.map((it) => (
              <div className="testimonial tw-rounded-lg tw-relative tw-h-96 tw-w-96 tw-py-8 tw-px-6">
                <div className="tw-flex tw-justify-start tw-items-start tw-h-full tw-gap-6">
                  <img src={Quote} alt="" className="" />
                  <div className="tw-relative tw-h-full">
                    <div>
                      {" "}
                      <p className="lg:tw-text-xl satoshi tw-font-light tw-text-white">
                        {it?.text}
                      </p>
                    </div>
                    <div className="tw-absolute tw-bottom-2">
                      <div className="tw-flex tw-gap-4 tw-items-center">
                        <div className="tw-h-1 tw-w-12 tw-bg-white"></div>
                        <strong className="tw-text-base tw-text-white tw-font-bold inter">
                          {it?.name}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <Swiper
            className="tw-hidden lg:tw-block"
            breakpoints={{
              320: {
                slidesPerView: 1,
              },
              480: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 1,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            spaceBetween={10}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Mousewheel]}
          >
            {Arr?.map((it, i) => (
              <SwiperSlide key={i}>
                <div className="testimonial tw-hidden lg:tw-block tw-rounded-lg tw-relative tw-h-96 tw-w-96 tw-py-8 tw-px-6">
                  <div className="tw-flex tw-justify-start tw-items-start tw-h-full tw-gap-6">
                    <img src={Quote} alt="" className="" />
                    <div className="tw-relative tw-h-full">
                      <div>
                        {" "}
                        <p className="tw-text-xl satoshi tw-font-light tw-text-white">
                          {it?.text}
                        </p>
                      </div>
                      <div className="tw-absolute tw-bottom-2">
                        <div className="tw-flex tw-gap-4 tw-items-center">
                          <div className="tw-h-1 tw-w-12 tw-bg-white"></div>
                          <strong className="tw-text-base tw-text-white tw-font-bold inter">
                            {it?.name}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

const Features = () => {
  const Arr = [
    {
      title: "Effective Administration",
      desc: "Qui vont nous permettre d'échanger, de débattre, de proposer, de co-créer et de valider.",
    },
    {
      title: "Seamless Learning",
      desc: "Qui vont nous permettre d'échanger, de débattre, de proposer, de co-créer et de valider.",
    },
    {
      title: "Effective Communication",
      desc: "Qui vont nous permettre d'échanger, de débattre, de proposer, de co-créer et de valider.",
    },
    {
      title: "Mobile Application",
      desc: "Qui vont nous permettre d'échanger, de débattre, de proposer, de co-créer et de valider.",
    },
    {
      title: "Effective Administration",
      desc: "Qui vont nous permettre d'échanger, de débattre, de proposer, de co-créer et de valider.",
    },
    {
      title: "Effective Administration",
      desc: "Qui vont nous permettre d'échanger, de débattre, de proposer, de co-créer et de valider.",
    },
  ];
  return (
    <section className="tw-w-full tw-py-20 tw-bg-white">
      <div className="container tw-bg-[#2242B4] tw-rounded-3xl tw-py-10 tw-px-14">
        <h2 className="lg:tw-text-5xl tw-text-2xl tw-text-white satoshi tw-text-center tw-font-bold">
          12 in 1 Features SMS
        </h2>
        <p className="tw-pt-5 lg:tw-text-base tw-text-sm tw-text-center tw-mx-auto tw-max-w-2xl tw-font-light tw-text-white">
          Créer une équipe pour votre projet c’est bien, avoir une organisation
          sans faille c’est mieux. Pourquoi ? Pour gagner en productivité, en
          temps et en ébullition d’idées.
        </p>
        <div className="tw-mt-10 tw-grid lg:tw-grid-cols-3 tw-mx-14 tw-gap-12">
          {Arr?.map((it) => (
            <div key={it} className="">
              <img src={Icons} alt="" className="tw-mx-auto" />
              <h4 className="lg:tw-text-2xl tw-pt-2 tw-fomt-medium tw-text-white satoshi tw-text-center">
                {it?.title}
              </h4>
              <p className="tw-text-center tw-font-light satoshi lg:tw-text-base tw-text-sm tw-text-white">
                {it?.desc}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export const JoinSection = () => {
  return (
    <section className="tw-w-full tw-bg-white tw-py-10">
      <div className="container tw-flex tw-justify-between tw-gap-10 lg:tw-gap-0 tw-flex-col lg:tw-flex-row tw-w-full tw-items-center">
        <div className="lg:tw-w-96">
          <h3 className="lg:tw-text-5xl tw-text-2xl tw-text-black tw-font-medium tw-text-left satoshi tw-capitalize">
            join us today!
          </h3>
          <p className="tw-pt-5 tw-text-base tw-font-medium tw-text-black satoshi">
            Elevate your institution with our School Management System. From
            administration to academics, we've got you covered
          </p>
          <button className="tw-mt-6 tw-w-full tw-bg-yellow tw-rounded-2xl tw-text-lg tw-font-medium tw-h-12 tw-text-plain satoshi">
            Subscribe to Our Newsletter
          </button>
        </div>
        <div className="tw-flex tw-flex-col lg:tw-flex-row tw-gap-6">
          <img src={Join1} alt="" className="" />
          <img src={Join2} alt="" className="" />
        </div>
      </div>
    </section>
  );
};
export default Home;
