import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routers from "./Routes";
import $ from "jquery";
import { SetAuthToken, SetDefaultHeaders, TOKEN } from "./data/Config";
import { useEffect } from "react";
import useGenFetcher from "./data/useGenFetcher";

// Preloader
$(window).on("load", function () {
	$(".lds-ellipsis").fadeOut(); // will first fade out the loading animation
	$(".preloader").delay(333).fadeOut("slow"); // will fade out the white DIV that covers the website.
	$("body").delay(333);
});

SetDefaultHeaders();

if (localStorage.getItem(TOKEN)) {
	SetAuthToken(localStorage.getItem(TOKEN));
}

function App() {
	let { loadUser } = useGenFetcher();
	useEffect(() => {
		loadUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Router>
			<Routers />
		</Router>
	);
}

export default App;
