import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import Medal from "../assets/medal.svg";
import Playstore from "../assets/play.svg";
import Appstore from "../assets/play2.svg";
import Seamleas1 from "../assets/seamless1.png";
import Seamless2 from "../assets/seamless2.png";
import Mark from "../assets/icons/mark.svg";
import Tools from "../assets/tools.png";
import { JoinSection, Trusted } from "./home";
import Manage from "../assets/manage.png";

const AboutUs = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <MainSection />
      <Seamless />
      <SchoolsSection />
      <Cta />
      <Trusted />
      <JoinSection />
      <Footer />
    </div>
  );
};

const HeroSection = () => {
  return (
    <section className="tw-w-full tw-h-screen about">
      <div className="container tw-flex lg:tw-justify-center tw-items-center tw-h-full">
        <div className="lg:tw-w-5/6 tw-mx-auto tw-flex tw-flex-col lg:tw-flex-row tw-gap-20 lg:tw-items-center">
          <div>
            <img src={Medal} alt="" className="tw-hidden lg:tw-block" />
          </div>
          <div>
            <div className="tw-w-40 tw-h-10 tw-bg-transparent tw-border-[0.2px] tw-border-white tw-text-xs tw-font-bold tw-text-[#D89925] tw-rounded-full tw-flex tw-justify-center tw-items-center">
              Empowering Education
            </div>
            <h1 className="lg:tw-text-5xl tw-text-2xl lg:tw-font-bold tw-text-white kum">
              We Provide Future-Ready Education!{" "}
            </h1>
            <p className="lg:tw-text-base tw-text-[11px] tw-text-white tw-pt- satoshi tw-font-normal">
              Welcome to the next generation of school management. Experience
              the difference with our School Management System.
            </p>
            <button className="tw-h-10 tw-mt-10 tw-w-32 tw-bg-yellow tw-rounded-full tw-text-base tw-font-bold tw-text-white satoshi">
              Get Started
            </button>
            <div className="tw-mt-10 lg:tw-flex tw-space-y-5 lg:tw-space-y-0 tw-flex-col lg:tw-flex-row tw-gap-4">
              <img src={Appstore} alt="" className="tw-h-12 lg:tw-h-full" />
              <img src={Playstore} alt="" className="tw-h-12 lg:tw-h-full" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const MainSection = () => {
  const Arr = [
      {
        value: "1000",
        desc: "Teachers",
      },
      {
        value: "30",
        desc: "Schools and Educations",
        bg: "#FFC000",
      },
      {
        value: "500",
        desc: "Parents and Guardians",
      },
      {
        value: "2000",
        desc: "Students",
      },
    ],
    Arr2 = [
      {
        value: "2022",
        desc: "Staff of operation",
      },
      {
        value: "20+",
        desc: "Tested and trusted solutions",
      },
      {
        value: "24/7",
        desc: "Customer supports and services",
      },
      {
        value: "100%",
        desc: "Reliability and efficiency",
      },
    ];
  return (
    <section className="tw-w-full tw-py-20 tw-bg-white">
      <div className="container">
        <h2 className="lg:tw-text-5xl tw-text-2xl tw-font-bold kum tw-text-black tw-text-center">
          ABOUT EDU SMS
        </h2>
        <p className="tw-max-w-2xl tw-font-normal tw-text-plain tw-pt-8 tw-text-center tw-mx-auto">
          At EDU SMS, Beyond Boundaries, our School Management System redefines
          the limits of education management, simplifies school management,
          enhance collaboration, inspire learning and makes administration a
          breeze. From administration to academics, we've got you covered!
        </p>
        <div className="tw-mt-10 tw-grid lg:tw-grid-cols-4 tw-w-5/6 tw-mx-auto">
          {Arr?.map((it) => (
            <div
              style={{
                background: it?.bg,
              }}
              className="tw-flex tw-h-40 lg:tw-border-r lg:tw-border-b lg:tw-border-b-yellow  lg:tw-border-r-yellow tw-justify-center tw-items-center"
            >
              <div>
                {" "}
                <h3 className="tw-text-3xl tw-text-center tw-text-black tw-font-medium satoshi">
                  {it?.value}+
                </h3>
                <h6 className="tw-text-xl tw--y-4 tw-text-black tw-font-normal satoshi tw-text-center">
                  {it?.desc}
                </h6>
              </div>
            </div>
          ))}
        </div>
        <div className="tw-grid lg:tw-grid-cols-4 tw-w-5/6 tw-mx-auto">
          {Arr2?.map((it) => (
            <div className="tw-flex tw-h-40 lg:tw-border-r  lg:tw-border-r-yellow tw-justify-center tw-items-center">
              <div>
                <h3 className="tw-text-3xl tw-text-center tw-text-black tw-font-medium satoshi">
                  {it?.value}
                </h3>
                <h6 className="tw-text-xl tw-pt-4 tw-text-black tw-font-normal satoshi tw-text-center">
                  {it?.desc}
                </h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const Seamless = () => {
  return (
    <section className="tw-w-full tw-py-16 tw-bg-white">
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between">
        <div>
          <img src={Seamleas1} alt="" className="lg:tw-h-[420px] lg:tw-w-96" />
        </div>
        <div className="tw-w-full tw-flex tw-h-[420px] tw-justify-center tw-items-center">
          <div>
            <h2 className="tw-text-5xlt tw-text-center tw-font-bold tw-text-black kum">
              Seamless School <br /> Operations
            </h2>
            <p className="tw-text-base tw-text-black tw-pt-6 tw-text-center tw-max-w-2xl tw-mx-auto inter">
              From admissions to attendance, experience the{" "}
              <span className="tw-font-bold satoshi">
                ease of managing your school
              </span>{" "}
              with our intuitive system. Innovate. Educate. Succeed: Transform
              your school's administration with our School Management System.
              The future of education starts here. Our School Management System
              brings harmony to every aspect of school life
            </p>
            <div className="tw-mt-10 tw-flex tw-justify-center">
              <button className="tw-bg-[#2141B0] tw-h-16 tw-w-64 tw-rounded-full tw-text-lg tw-font-medium inter tw-text-white tw-capitalize">
                join us today
              </button>
            </div>
          </div>
        </div>
        <div>
          <img src={Seamless2} alt="" className="lg:tw-h-[420px] lg:tw-w-96" />
        </div>
      </div>
    </section>
  );
};

const SchoolsSection = () => {
  const [tab, setTab] = useState("finance");
  const tabs = [
      "finance",
      "e-library",
      "administration",
      "admissions",
      "grading",
      "virtual classes",
      "attendance",
    ],
    Features = [
      "Manage all finances and payments",
      "Manage all finances and payments",
      "Manage all finances and payments",
      "Manage all finances and payments",
      "Manage all finances and payments",
      "Manage all finances and payments",
      "Manage all finances and payments",
      "Manage all finances and payments",
    ];
  return (
    <section className="tw-w-full lg:tw-py-20 tw-py-10 tw-bg-white">
      <div className="container">
        <h2 className="lg:tw-text-5xl tw-text-2xl tw-w-80 lg:tw-w-full tw-text-darkblue tw-font-bold kum">
          Built for all schools with effective tools
        </h2>
        <div className="tw-mt-8 lg:tw-flex tw-hidden tw-gap-4">
          {tabs?.map((it) => (
            <button
              onClick={() => setTab(it)}
              className={`tw-h-12 tw-px-5 tw-border tw-rounded-3xl tw-border-[#F2F2F2] ${
                tab === it && "tw-bg-[#F2F2F2]"
              } tw-text-darkblue tw-font-normal tw-capitalize satoshi tw-text-sm`}
            >
              {it}
            </button>
          ))}
        </div>
        <div className="tw-mt-8 tw-grid tw-grid-cols-3 lg:tw-hidden tw-gap-4">
          {tabs?.map((it) => (
            <button
              onClick={() => setTab(it)}
              className={`tw-h-12 tw-px-5 tw-border tw-rounded-3xl tw-border-[#F2F2F2] ${
                tab === it && "tw-bg-[#F2F2F2]"
              } tw-text-darkblue tw-font-normal tw-capitalize satoshi tw-text-sm`}
            >
              {it}
            </button>
          ))}
        </div>
        <div className="tw-mt-6 tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-gap-6">
          <div className="lg:tw-w-[20%]">
            <div className="tw-space-y-4">
              {Features?.map((it, i) => (
                <div key={i} className="tw-flex tw-items-center tw-gap-6">
                  <img src={Mark} alt="" className="" />
                  <div>
                    <small className="tw-text-[#05003899] tw-text-base satoshi tw-font-normal">
                      {it}
                    </small>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="lg:tw-w-[80%]">
            <img src={Tools} alt="" className="lg:tw-block tw-hidden" />
            <img src={Manage} alt="" className="lg:tw-hidden tw-block" />
          </div>
        </div>
      </div>
    </section>
  );
};
const Cta = () => {
  return (
    <section
      style={{
        boxShadow: "0px 20px 40px 0px #2242B41A",
      }}
      className="tw-w-full tw-py-16 tw-mt-10 tw-mb-20 tw-bg-[#2242B41A]"
    >
      <div className="container tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-justify-between">
        <div>
          <h3 className="tw-text-3xl tw-font-bold tw-text-black satoshi tw-max-w-sm">
            Having troubles getting
            <br /> started? <br /> Let’s walk you through the process
          </h3>
        </div>
        <div>
          <p className="tw-text-lg tw-text-black tw-font-normal kum tw-max-w-lg">
            An invaluable tool for educators and administrators alike! Tested
            and trusted! The School Management System has simplified complex
            processes, allowing you to focus more on what matters – quality
            education.
          </p>
        </div>
        <button className="tw-bg-[#2242B4] tw-h-12 tw-w-36 tw-rounded kum tw-text-white tw-font-bold tw-text-sm">
          Contact Us
        </button>
      </div>
    </section>
  );
};
export default AboutUs;
