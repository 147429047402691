import React from "react";
import { Route, Routes } from "react-router-dom";
// import useAuthStore from "./data/Stores/AuthStore";
import Home from "./screens/home";
import PageRender from "./PageRender";
import { ToastContainer } from "react-toastify";
import { ModalContainer, PrimaryBtn } from "./components/inputs";
import useErrorStore from "./data/Stores/ErrrorStore";

const Routers = () => {
	//   const { isAuth } = useAuthStore();
	const { error, clearErrors } = useErrorStore();
	return (
		<>
			<ToastContainer />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/:page" element={<PageRender />} />
				<Route path="/:page/:id" element={<PageRender />} />
				<Route path="/:page/:id/:step" element={<PageRender />} />
			</Routes>
			{error?.length > 0 && (
				<ModalContainer handleClose={() => clearErrors()}>
					<div className="tw-p-5">
						<h2 className="tw-font-bold satoshi tw-text-red-800 tw-text-2xl">
							Error
						</h2>
						<div className="downH2 tw-flex tw-flex-col">
							{error?.map((item, i) => (
								<p key={i} className="tw-fw-bold Lexend tw-w-100">
									<span className="fontInherit tw-me-2">
										{error?.length !== 1 && <>{i + 1}.</>}
									</span>{" "}
									{item?.message || item || null}{" "}
								</p>
							))}
							<div className=" tw-mt-4 tw-flex tw-justify-center tw-py-3">
								<PrimaryBtn
									onClick={clearErrors}
									text={"Close"}
									width={
										"tw-bg-yellow tw-h-10 tw-w-32 tw-rounded-xl tw-text-base tw-font-bold tw-text-black satoshi"
									}
								/>
							</div>
						</div>
					</div>
				</ModalContainer>
			)}
		</>
	);
};

export default Routers;
