import React, { useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import Logo from "../logo";
import { FaSearch } from "react-icons/fa";
import { IoMdNotificationsOutline } from "react-icons/io";
import { CiMenuFries } from "react-icons/ci";
import OutsideClickHandler from "react-outside-click-handler/build/OutsideClickHandler";
import useAuthStore from "../../data/Stores/AuthStore";

const Header = () => {
	const navigate = useNavigate(),
		[authNav, setAuthNav] = useState(false),
		[nav, setNav] = useState(false),
		location = useLocation(),
		{ isAuth, logout } = useAuthStore(),
		navRef = useRef(null);
	const handleClickOutside = event => {
		setNav(false) || setAuthNav(false);
	};
	const links = [
		{
			name: "Home",
			url: "/",
		},
		{
			name: "About",
			url: "/about",
		},
		{
			name: "Events and Blog",
			url: "/event",
		},
		{
			name: "Contact",
			url: "/contact",
		},
		{
			name: "FAQs",
			url: "/faq",
		},
		{
			name: "Pricing",
			url: "/pricing",
		},
	];
	return (
		<div>
			<div className="tw-w-full tw-h-16 tw-bg-gray-900">
				<div className="container tw-flex tw-justify-between tw-h-full tw-items-center">
					<div
						onClick={() => setNav(!nav)}
						className="tw-block tw-cursor-pointer lg:tw-hidden">
						<CiMenuFries color="white" size={20} />
					</div>
					<div className="lg:tw-flex tw-hidden tw-gap-6 tw-h-full">
						{links?.map(it => (
							<strong
								onClick={() => navigate(it?.url)}
								className={`tw-text-sm tw-cursor-pointer tw-pt-4 tw-font-medium tw-inter tw-text-[#8C94A3] ${
									location?.pathname === it?.url
										? "tw-border-t-2 tw-pt-3 tw-border-t-[#FFC000] tw-text-white"
										: ""
								}`}>
								{it?.name}
							</strong>
						))}
					</div>
					<div className="tw-flex tw-gap-8 tw-items-center">
						<div className="tw-flex tw-gap-2 tw-items-center">
							<small className="tw-text-sm tw-font-normal tw-text-gray-400">
								USD
							</small>
							<IoIosArrowDown color="rgb(156, 163, 175)" size={15} />
						</div>
						<div className="tw-flex tw-gap-2 tw-items-center">
							<small className="tw-text-sm tw-font-normal tw-text-gray-400">
								English
							</small>
							<IoIosArrowDown color="rgb(156, 163, 175)" size={15} />
						</div>
					</div>
				</div>
			</div>
			<div
				style={{
					boxShadow: "0px 10px 20px 0px #1777F71A",
				}}
				className="tw-h-20 tw-w-full tw-bg-white">
				<div className="container tw-flex tw-justify-between tw-items-center tw-h-full">
					<div className="tw-flex tw-gap-8 tw-items-center">
						<div className="">
							<Logo />
						</div>
						<select
							name=""
							id=""
							className="tw-h-12 tw-hidden lg:tw-block tw-w-40 tw-border-[#E9EAF0] tw-border tw-bg-white tw-px-2 tw-rounded tw-text-gray-700 tw-text-base tw-font-normal">
							<option value="browse" className="">
								Browse
							</option>
						</select>
						<div className="tw-h-12 tw-hidden lg:tw-block tw-w-72 tw-border-[#E9EAF0] tw-border tw-rounded tw-relative">
							<div className="tw-absolute tw-top-4 tw-left-3">
								<FaSearch />
							</div>
							<input
								type="text"
								name=""
								className="tw-h-full tw-w-full tw-pl-12 tw-text-base focus:tw-border tw-font-normal tw-text-gray-500"
								id=""
								placeholder="What do you want to see?"
							/>
						</div>
					</div>
					<div
						onClick={() => setAuthNav(true)}
						className="tw-block lg:tw-hidden">
						<CiMenuFries color="black" size={20} />
					</div>
					<div className="lg:tw-flex tw-hidden tw-items-center tw-gap-4">
						<IoMdNotificationsOutline size={20} />
						{!isAuth && (
							<button
								onClick={() => navigate("/register")}
								className="tw-border tw-border-[#FFC000] tw-h-12 tw-w-40 tw-text-[#FFC000] satoshi tw-text-base tw-font-bold tw-rounded">
								Create Account
							</button>
						)}
						<button
							onClick={() => (isAuth ? logout() : navigate("/login"))}
							className="tw-bg-yellow tw-h-12 tw-w-24 tw-rounded tw-text-base tw-font-bold tw-text-white satoshi">
							{isAuth ? "Log Out" : "Sign in"}
						</button>
					</div>
				</div>
			</div>
			<OutsideClickHandler onOutsideClick={handleClickOutside}>
				<div
					ref={navRef}
					className={`tw-fixed tw-z-[1000] tw-p-7 tw-bg-white tw-w-1/2 tw-top-0 tw-shadow-lg ${
						nav
							? "tw-translate-x-0 tw-duration-300 tw-ease-in-out"
							: "-tw-translate-x-full tw-duration-300 tw-ease-in-out"
					}`}>
					<div className="tw-space-y-4">
						{links?.map(it => (
							<h6
								onClick={() => navigate(it?.url)}
								className="tw-text-plain tw-font-normal tw-text-sm satoshi">
								{it?.name}
							</h6>
						))}
					</div>
				</div>
			</OutsideClickHandler>
			<OutsideClickHandler onOutsideClick={handleClickOutside}>
				<div
					className={`tw-fixed tw-z-[1000] tw-p-7 tw-bg-white tw-w-1/2 tw-top-0 tw-shadow-lg ${
						authNav
							? "tw-translate-x-0 tw-duration-300 tw-ease-in-out"
							: "-tw-translate-x-full tw-duration-300 tw-ease-in-out"
					}`}>
					<div className="tw-space-y-4">
						<h6
							onClick={() => (isAuth ? logout() : navigate("/login"))}
							className="tw-text-plain tw-font-normal tw-text-sm satoshi">
							{isAuth ? "Log Out" : "Login"}
						</h6>
						{!isAuth && (
							<h6
								onClick={() => navigate("/register")}
								className="tw-text-plain tw-font-normal tw-text-sm satoshi">
								Register
							</h6>
						)}
					</div>
				</div>
			</OutsideClickHandler>
		</div>
	);
};

export default Header;
